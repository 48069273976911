import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, Subject } from 'rxjs';
import { ActiveConnection } from '../models/ActiveConnections';
import { UserResponse } from '../models/users.model';
import { LocationResponse, LocationSelection } from '../models/location.model';
import {
  IDBModel,
  IDBObjectStoreModel,
  IndexedDBService,
} from './IndexedDB.service';
import { AuthService } from './AuthService';
import { SimulatorResponse } from '../models/simulators.model';

@Injectable({
  providedIn: 'root',
})
export class RestApiService {
  private userSub = new Subject<UserResponse>();
  public userSub$ = this.userSub.asObservable();

  private _user: UserResponse;
  public get user(): UserResponse {
    return this._user;
  }
  public set user(v: UserResponse) {
    this._user = new UserResponse({ ...v });
    this.userSub.next(v);
  }

  private _companyLocations: LocationResponse[];
  public get getCompanyLocations(): LocationResponse[] {
    return this._companyLocations;
  }
  public set setCompanyLocations(locations: LocationResponse[]) {
    this._companyLocations = locations;
  }

  constructor(
    public httpClient: HttpClient,
    private iDBService: IndexedDBService,
    private authService: AuthService
  ) {}

  public initialDBObjects(): void {
    this.iDBService.initialDB(
      new IDBModel({
        name: 'application_db',
        version: 1,
        objectStores: new Array<IDBObjectStoreModel>(
          {
            name: 'logs',
            params: {
              autoIncrement: true,
              keyPath: 'id',
            },
            indexes: [
              {
                name: 'id',
                keyPath: 'id',
                options: {
                  unique: true,
                },
              },
              {
                name: 'type',
                keyPath: 'type',
                options: {
                  unique: false,
                },
              },
              {
                name: 'message',
                keyPath: 'message',
                options: {
                  unique: false,
                },
              },
            ],
          },
          {
            name: 'notifs',
            params: {
              autoIncrement: true,
              keyPath: 'id',
            },
            indexes: [
              {
                name: 'type',
                keyPath: 'type',
                options: {
                  unique: false,
                },
              },
              {
                name: 'extendSession',
                keyPath: 'extendSession',
                options: {
                  unique: false,
                },
              },
              {
                name: 'assistance',
                keyPath: 'assistance',
                options: {
                  unique: false,
                },
              },
              {
                name: 'alertBody',
                keyPath: 'alertBody',
                options: {
                  unique: false,
                },
              },
            ],
          }
        ),
      })
    );
  }

  public getAllClients(): Observable<ActiveConnection[]> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    // headers = headers.set('Authorization', `Bearer ${tokenId}`);
    return this.httpClient
      .get<ActiveConnection[]>(environment.awsAPIURL, { headers: headers })
      .pipe((res) => {
        const objects = res || null;
        return objects;
      });
  }

  public deleteSocketConnection(tabId): Observable<ActiveConnection[]> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    // headers = headers.set('Authorization', `Bearer ${tokenId}`);
    return this.httpClient
      .delete<ActiveConnection[]>(environment.awsAPIURL, {
        headers: headers,
        body: { sim_id: tabId },
      })
      .pipe((res) => {
        const objects = res || null;
        return objects;
      });
  }

  public getUserByUserId(cognito_id: any): Observable<UserResponse> {
    if (!cognito_id) {
      return null;
    }

    const url = environment.apiServer + `/Users/bm/${cognito_id}`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');

    return this.httpClient
      .get<UserResponse>(url, { headers: headers })
      .pipe((res) => {
        const objects = res || null;
        return objects;
      });
  }

  public getLocationsSelectList(): Observable<LocationSelection[]> {
    const url =
      environment.apiServer +
      `/Locations/GetLocationsSelectList/${this.user?.company?.id}`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');

    return this.httpClient
      .get<LocationSelection[]>(url, { headers: headers })
      .pipe((res) => {
        const objects = res || null;
        return objects;
      });
  }

  public getLocationNameBySimulatorId(simulatorId: string): Observable<string> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    // headers = headers.set('Authorization', `Bearer ${tokenId}`);
    return this.httpClient
      .get<string>(
        environment.apiServer +
          `/Locations/GetLocationNameBySimulatorId/${simulatorId}`,
        { headers: headers }
      )
      .pipe((res) => {
        const objects = res || null;
        return objects;
      });
  }

  public getCompanyName(userId: string): Observable<string> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    // headers = headers.set('Authorization', `Bearer ${tokenId}`);
    return this.httpClient
      .get<string>(
        environment.apiServer + `/Companies/GetCompanyNameByUserId/${userId}`,
        { headers: headers }
      )
      .pipe((res) => {
        const objects = res || null;
        return objects;
      });
  }

  public getSimulatorByIdentifier(
    identifier: string
  ): Observable<SimulatorResponse> {
    const url =
      environment.apiServer +
      `/Simulator/GetSimulatorByIdentifier/${identifier}`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');

    return this.httpClient
      .get<SimulatorResponse>(url, { headers: headers })
      .pipe((res) => {
        const p = res || null;
        return p;
      });
  }
}
