import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})

/**
 * Generic http service api
 *
 * @param {W} //View item response
 * @param {M} //Model item response
 * @param {C} //Create item response
 * @param {U} //Update item response
 */
export class HttpService<W, M, C, U> {
  private _url: string;

  public get getUrl(): string {
    return this._url;
  }

  constructor(private httpClient: HttpClient) {
    this._url = environment.apiServer;
  }

  public getItems(path: string): Observable<W[]> {
    const url = this._url + path;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');

    return this.httpClient
      .get<W[]>(url, {
        headers: headers,
      })
      .pipe((res) => {
        const p = res || null;
        return p;
      });
  }

  public getItemById(id: number, path: string): Observable<M> {
    const url = this._url + path + `\\${id}`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');

    return this.httpClient.get<M>(url, { headers: headers }).pipe((res) => {
      const p = res || null;
      return p;
    });
  }

  public createItem(data: any, path: string): Observable<C> {
    const url = this._url + path;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');

    return this.httpClient
      .post<C>(url, data, { headers: headers })
      .pipe((res) => {
        const p = res || null;
        return p;
      });
  }

  public updateItem(data: any, path: string): Observable<U> {
    const url = this._url + path;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');

    return this.httpClient
      .put<U>(url, data, { headers: headers })
      .pipe((res) => {
        const p = res || null;
        return p;
      });
  }

  public removeItem(data: any, path: string): Observable<any> {
    const url = this._url + path;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');

    return this.httpClient
      .delete<any>(url, {
        headers: headers,
        params: {
          ...data,
        },
      })
      .pipe((res) => {
        const p = res || null;
        return p;
      });
  }

  public removeItemFromBody(data: any, path: string): Observable<any> {
    const url = this._url + path;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');

    return this.httpClient
      .delete<any>(url, { headers: headers, body: data })
      .pipe((res) => {
        const p = res || null;
        return p;
      });
  }
}
