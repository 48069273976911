import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class IndexedDBService {
  constructor(private translate: TranslateService) {
    this.browserSupportIDB();
  }

  public browserSupportIDB(): void {
    if (!('indexedDB' in window)) {
      alert(this.translate.instant('browserDoNotSupportIndexedDB'));
      return;
    }
  }

  public initialDB(db: IDBModel): Promise<any> {
    return new Promise((resolve, reject) => {
      const dbRequest = window.indexedDB.open(db.name, db.version);

      dbRequest.onerror = reject;
      dbRequest.onsuccess = (e: any) => resolve(e.target.result);

      dbRequest.onupgradeneeded = (e: any) => {
        let result = e.target.result;

        for (let i = 0; i < db.objectStores.length; i++) {
          let store = db.objectStores[i];

          if (!result.objectStoreNames.contains(store.name)) {
            let objStoreResult = result.createObjectStore(store.name, {
              autoIncrement: store.params.autoIncrement,
              keyPath: store.params.keyPath,
            });

            for (let j = 0; j < store.indexes.length; j++) {
              let index = store.indexes[j];

              if (index) {
                objStoreResult.createIndex(
                  index.name,
                  index.keyPath,
                  index.options
                );
              }

              console.log('Create index : ', index.name);
            }

            console.log('Create object store : ', store.name);
          }
        }
      };
    });
  }

  public openDB(db: string, ver?: number): Promise<IDBDatabase> {
    return new Promise((resolve, reject) => {
      const dbRequest = window.indexedDB.open(db, ver);
      dbRequest.onerror = reject;
      dbRequest.onsuccess = (e: any) => resolve(e.target.result);
    });
  }

  public insertRecord(db: string, store: string, data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.openDB(db)
        .then((dbRequest) => {
          let transact = dbRequest.transaction([store], 'readwrite');
          let objStore = transact.objectStore(store);
          let addRequest = objStore.add(data);

          addRequest.onsuccess = (e: any) => {
            console.log('Add Success');
            resolve(e.target.result);
          };
        })
        .catch((err) => reject(err));
    });
  }

  public getRecord(db: string, store: string, key: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.openDB(db)
        .then((dbRequest) => {
          let transact = dbRequest.transaction([store], 'readwrite');
          let objStore = transact.objectStore(store);
          let getRequest = objStore.get(key);

          getRequest.onsuccess = (e: any) => {
            resolve(e.target.result);
          };
        })
        .catch((err) => reject(err));
    });
  }

  public getAllRecords(db: string, store: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.openDB(db)
        .then((dbRequest) => {
          let transact = dbRequest.transaction([store], 'readwrite');
          let objStore = transact.objectStore(store);
          let getRequest = objStore.getAll();

          getRequest.onsuccess = (e: any) => {
            resolve(e.target.result);
          };
        })
        .catch((err) => reject(err));
    });
  }

  public putRecord(db: string, store: string, data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.openDB(db)
        .then((dbRequest) => {
          let transact = dbRequest.transaction([store], 'readwrite');
          let objStore = transact.objectStore(store);
          let getRequest = objStore.put(data);

          getRequest.onsuccess = (e: any) => {
            console.log('Update Success');
            resolve(e.target.result);
          };
        })
        .catch((err) => reject(err));
    });
  }

  public removeRecord(db: string, store: string, key: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.openDB(db)
        .then((dbRequest) => {
          let transact = dbRequest.transaction([store], 'readwrite');
          let objStore = transact.objectStore(store);
          let getRequest = objStore.delete(key);

          getRequest.onsuccess = (e: any) => {
            console.log('Remove Success');
            resolve(e.target.result);
          };
        })
        .catch((err) => reject(err));
    });
  }

  public removeAllRecords(db: string, store: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.openDB(db)
        .then((dbRequest) => {
          let transact = dbRequest.transaction([store], 'readwrite');
          let objStore = transact.objectStore(store);
          let getRequest = objStore.clear();

          getRequest.onsuccess = () => {
            console.log('Remove All Success');
            resolve(null);
          };
        })
        .catch((err) => reject(err));
    });
  }

  public dropDatabase(db: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const result = window.indexedDB.deleteDatabase(db);
      result.onsuccess = () => {
        console.log('Drop db success');
        resolve(null);
      };
      result.onerror = reject;
    });
  }
}

export class IDBIndexParametersModel {
  name?: string;
  keyPath?: string;
  options?: IDBIndexParameters;

  constructor(model: IDBIndexParametersModel) {
    this.name = model.name || '';
    this.keyPath = model.keyPath || '';
    this.options = model.options || (null as any);
  }
}

export class IDBObjectStoreModel {
  name?: string;
  params?: IDBObjectStoreParameters;
  indexes?: IDBIndexParametersModel[];

  constructor(model: IDBObjectStoreModel) {
    this.name = model.name || '';
    this.params = model.params || (null as any);
  }
}

export class IDBModel {
  name?: string;
  version?: number;
  objectStores?: IDBObjectStoreModel[];

  constructor(model: IDBModel) {
    this.name = model.name || '';
    this.version = model.version || 1;
    this.objectStores = model.objectStores || (null as any);
  }
}
