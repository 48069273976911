import { Assistance } from './assistance';
import { ExtendSession } from './extend-session';
import { SimulatorResponse } from './simulators.model';

export class Alert {
  type: AlertType;
  extendSession: ExtendSession;
  assistance: Assistance;
  simulator?: SimulatorResponse;
  alertBody?: string;
  
  constructor(model: Alert) {
    this.type = model.type || null;
    this.extendSession = model.extendSession || null;
    this.assistance = model.assistance || null;
    this.alertBody = model.alertBody || '';
    this.simulator = model.simulator || null;
  }
}

export enum AlertType {
  extendSession,
  assistance,
  simulator,
}
