import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Auth,  } from 'aws-amplify';
import {  CognitoIdToken } from 'amazon-cognito-identity-js';
import { from, Observable } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AuthService } from './services/AuthService';

/**
 * This will append jwt token for the http requests.
 *
 * @export
 * @class JwtInterceptor
 * @implements {HttpInterceptor}
 */
@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor( private authService: AuthService) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {


    return from(this.authService.getIdToken()).pipe(
      switchMap((token: CognitoIdToken) => {
        // switchMap() is used instead of map().
        
        //let jwt = auth.idToken.jwtToken;
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${token.getJwtToken()}`,
          },
        });
       //console.log('Cloned', request);
        return next.handle(request);
      }),
      catchError((err) => {
        console.log('Error ', err);
        return next.handle(request);
      })
    );
  }
}


// let jwt = auth.accessToken.jwtToken;
// request = request.clone({
//   setHeaders: {
//     "Authorization": `Bearer ${jwt}`,
//   },