<div class="full-width flex-center flex-column mat-dialog-content" mat-dialog-content>
    <img src="/assets/images/BM-Logo-Sm.png" alt="logo">
    <h3 translate>licenseExpiredModalH3</h3>
    <div class="full-width">
        <hr>
    </div>
    <h5 translate>licenseExpiredModalH5</h5>
    <p translate>mondayFriday</p>
    <p translate>saturdaySunday</p>
    <p><span translate>telephoneSupport</span>: +1 858.675.1196</p>
    <p><span translate>email</span>: support@fullswinggolf.com</p>
    <div mat-dialog-actions>
        <div class="full-width flex-between">
            <div class="action-buttons">
                <button mat-button type="button" class="prm-btn fs-btn-txt save-btn" (click)="dialogRef.close()">
                    <span translate>close</span>
                </button>
            </div>
        </div>
    </div>
</div>