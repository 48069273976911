import { Injectable } from '@angular/core';
import { LogRequest, LogResponse } from './logs-sheet.model';
import { IndexedDBService } from '../../services/IndexedDB.service';

@Injectable()
export class LogsSheetService {
  private _logs: LogResponse[];
  public get getLogs(): LogResponse[] {
    return this._logs;
  }
  public set setLog(value: LogResponse[]) {
    this._logs = value;
  }

  constructor(private iDBService: IndexedDBService) {
    this._logs = new Array<LogResponse>();
  }

  public async fetchAllLogs(): Promise<void> {
    try {
      this._logs = await this.iDBService.getAllRecords(
        'application_db',
        'logs'
      );
    } catch (error) {
      console.error(error);
    }
  }

  public async addLog(log: LogRequest): Promise<void> {
    try {
      await this.iDBService.insertRecord('application_db', 'logs', log);
    } catch (error) {
      console.error(error);
    }
  }

  public async removeLog(id: number): Promise<void> {
    try {
      this._logs = this._logs.filter((f) => f.id !== id);
      await this.iDBService.removeRecord('application_db', 'logs', id);
    } catch (error) {
      console.error(error);
    }
  }

  public async removeAllLogs(): Promise<void> {
    try {
      this._logs = new Array<LogResponse>();
      await this.iDBService.removeAllRecords('application_db', 'logs');
    } catch (error) {
      console.error(error);
    }
  }
}
