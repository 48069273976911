import { Component, OnInit } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { LogsSheetService } from './logs-sheet.service';
import { LogType } from './logs-sheet.model';

@Component({
  selector: 'logs-sheet',
  templateUrl: './logs-sheet.component.html',
  styleUrls: ['./logs-sheet.component.scss'],
})
export class LogsSheetComponent implements OnInit {
  logType = LogType;

  constructor(
    public dialog: MatDialog,
    public logsSheetService: LogsSheetService,
    public _bottomSheetRef: MatBottomSheetRef<any>
  ) {}

  ngOnInit(): void {
    this.logsSheetService.fetchAllLogs();
  }

  printLogType(type: LogType): string {
    switch (type) {
      case LogType.Info:
        return 'INFO';
      case LogType.Warning:
        return 'WARNING';
      case LogType.Error:
        return 'ERROR';
      default:
        return '';
    }
  }

  removeLog(id: number): void {
    this.logsSheetService.removeLog(id);
  }
}
