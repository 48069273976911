<div class="header-modal-content">
    <div class="modal-icon"></div>
    <span class="modal-badge" *ngIf="data.walkIn">{{ helper.getMediumDate(eventControls.eSelectedDate.value,
        locale.currentLangId) }}</span>
    <h1 *ngIf="!data.walkIn" mat-dialog-title class="modal-title">{{ title }}</h1>
    <h1 *ngIf="data.walkIn" mat-dialog-title class="modal-title" translate>walkIn</h1>
    <button type="button" mat-button class="close-btn" (click)="dialogRef.close()">
        <img src="/assets/icons/modal-close.svg" alt="">
    </button>
</div>
<form [formGroup]="eventForm" (ngSubmit)="onSaveClick()">
    <div class="mat-dialog-content" mat-dialog-content>
        <div *ngIf="loaderService.loader; else noEventLoad" class="flex-center event-loading">
            <mat-spinner [diameter]="30"></mat-spinner>
            <span class="sp-title">
                <span translate>eventLoading</span>
            </span>
        </div>
        <ng-template #noEventLoad>
            <div class="form-header-controls">
                <button mat-button type="button" class="prm-btn fs-btn-txt" (click)="onOpenCustomerModal()">
                    <span translate>newCustomer</span>
                </button>
            </div>
            <div class="form-column-container">
                <div class="form-controls-column">
                    <mat-form-field appearance="fill">
                        <mat-label translate>customers</mat-label>
                        <input #customerInput type="text" matInput [matAutocomplete]="auto"
                            (keyup)="filterCustomers($event)" (change)="onChangeCustomer($event)">
                        <button type="button" matSuffix mat-icon-button aria-label="Clear"><mat-icon
                                style="font-size: 28px;">keyboard_arrow_down</mat-icon></button>
                        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                            <mat-option *ngFor="let customer of customers" [value]="customer.id"
                                (click)="onClickCustomerOption(customer)">
                                {{customer.fullName}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                    <mat-form-field class="input-field example-form-field" appearance="fill">
                        <mat-label translate>name</mat-label>
                        <input #eventName matInput type="text" [formControl]="eventControls.name">
                        <button type="button" *ngIf="eventName.value" matSuffix mat-icon-button aria-label="Clear"
                            (click)="helper.onClear($event, eventControls.name)">
                            <mat-icon>close</mat-icon>
                        </button>
                    </mat-form-field>
                    <mat-form-field class="input-field" appearance="fill">
                        <mat-label translate>simulator</mat-label>
                        <mat-select [formControl]="eventControls.simulatorId" required>
                            <mat-optgroup *ngFor="let location of locations" [label]="location.name"
                                [disabled]="!location.isActive">
                                <mat-option *ngFor="let simulator of location.simulators" [value]="simulator.id"
                                    [disabled]="simulator.isExpired" (click)="simulatorSelectedObjec = {
                                        location: location,
                                        simulator: simulator}">
                                    {{simulator.name}}
                                </mat-option>
                            </mat-optgroup>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="input-field" *ngIf="!data.walkIn" appearance="fill">
                        <mat-label translate>chooseADate</mat-label>
                        <input matInput [matDatepicker]="picker" min="2020-01-01" max="2030-12-31" readonly required
                            [formControl]="eventControls.eSelectedDate" (dateChange)="onTimeChange($event)"
                            id="eSelectedDate">
                        <mat-datepicker-toggle *ngIf="!eventService.isSessionActive" matIconSuffix
                            [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field *ngIf="data.walkIn" class="input-field button-wrapper" appearance="fill">
                        <mat-label><span translate>startIn</span></mat-label>
                        <input matInput type="text">
                        <div class="flex-center button-g btn-3">
                            <button mat-button type="button" class="prm-btn" (click)="increaseTime(1)">1 <span
                                    translate>min</span></button>
                            <button mat-button type="button" class="prm-btn" (click)="increaseTime(5)">5 <span
                                    translate>min</span></button>
                            <button mat-button type="button" class="prm-btn" (click)="increaseTime(10)">10 <span
                                    translate>min</span></button>
                        </div>
                    </mat-form-field>
                    <mat-form-field class="input-field" appearance="fill">
                        <mat-label translate>startTime</mat-label>
                        <input class="time-picker" type="text" readonly required matInput
                            [formControl]="eventControls.eStartTime">
                        <button *ngIf="!eventService.isSessionActive" type="button" mat-button class="input-action-btn"
                            (click)="resetStartTime()">
                            <img width="40px" height="40px" src="/assets/icons/reset-time.svg" alt="">
                        </button>
                        <ngx-mat-timepicker-toggle *ngIf="!eventService.isSessionActive" matSuffix
                            [for]="startTimePicker"></ngx-mat-timepicker-toggle>
                    </mat-form-field>
                    <ngx-mat-timepicker #startTimePicker [defaultTime]="eventControls.eStartTime.value"
                        (timeSet)="onTimeSet($event, 'start')" (opened)="openedTimePicker()"
                        (timeChanged)="changeStartTime($event)">
                    </ngx-mat-timepicker>
                </div>
                <div class="form-controls-column">
                    <mat-form-field class="text-area-field" appearance="fill">
                        <mat-label translate>customMessage</mat-label>
                        <textarea style="height: 180px;" matInput rows="4" maxlength="80"
                            [formControl]="eventControls.description"></textarea>
                    </mat-form-field>
                    <mat-form-field class="input-field button-wrapper"
                        [ngStyle]="{'top': !data.walkIn ? '-4px' : '0px'}" appearance="fill">
                        <mat-label><span translate>duration</span></mat-label>
                        <input matInput type="text">
                        <div class="flex-center button-g btn-2">
                            <button mat-button type="button" class="prm-btn "
                                [disabled]="!eventControls.eStartTime.value || !eventControls.eStartTime.value.length"
                                (click)="addDurationTime(30)">+ 30 <span translate>min</span></button>
                            <button mat-button type="button" class="prm-btn"
                                [disabled]="!eventControls.eStartTime.value || !eventControls.eStartTime.value.length"
                                (click)="addDurationTime(60)">+ 1 <span translate>hr</span></button>
                        </div>
                    </mat-form-field>
                    <mat-form-field class="input-field" appearance="fill">
                        <mat-label translate>endTime</mat-label>
                        <input class="time-picker" type="text" readonly required matInput
                            [formControl]="eventControls.eEndTime">
                        <button type="button" mat-button class="input-action-btn" (click)="resetEndTime()">
                            <img width="40px" height="40px" src="/assets/icons/reset-time.svg" alt="">
                        </button>
                        <ngx-mat-timepicker-toggle matSuffix [for]="endTimePicker"></ngx-mat-timepicker-toggle>
                    </mat-form-field>
                    <ngx-mat-timepicker #endTimePicker [defaultTime]="eventControls.eEndTime.value"
                        (timeSet)="onTimeSet($event, 'end')" (opened)="openedTimePicker()">
                    </ngx-mat-timepicker>

                </div>
            </div>
        </ng-template>
    </div>
    <div mat-dialog-actions>
        <div class="full-width flex-between">
            <div class="prm-btn"></div>
            <div class="action-buttons">
                <button mat-button type="submit" class="prm-btn fs-btn-txt save-btn"
                    [disabled]="!eventForm.valid || loaderService.loader || loaderService.actionLoader">
                    <span translate>save</span>
                </button>
                <button *ngIf="event && event?.id !== 0" mat-button type="button" class="remove-btn"
                    [disabled]="loaderService.loader || loaderService.actionLoader" (click)="removeEvent()">
                    <img src="/assets/icons/modal-remove.svg" alt="">
                </button>
            </div>
        </div>
    </div>
</form>