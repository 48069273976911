<div class="header-modal-content">
    <div class="modal-icon">

    </div>
    <h1 mat-dialog-title class="modal-title">
        <span *ngIf="modalType === 0; else editModal">
            <span translate>addCustomer</span>
        </span>
        <ng-template #editModal>
            <span translate>editCustomer</span>
        </ng-template>
    </h1>
    <button type="button" mat-button class="close-btn" (click)="dialogRef.close()">
        <img src="/assets/icons/modal-close.svg" alt="">
    </button>
</div>
<form [formGroup]="customerForm" (ngSubmit)="onSaveChanges()">
    <div class="text-center mat-dialog-content" mat-dialog-content>
        <div class="inline-form-control flex-center">
            <mat-form-field appearance="fill">
                <mat-label translate>firstName</mat-label>
                <input matInput required [formControl]="customerControls.firstName">
                <button *ngIf="customerControls.firstName.value" type="button" matSuffix mat-icon-button
                    aria-label="Clear" (click)="helperService.onClear($event, customerControls.firstName)">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label translate>lastName</mat-label>
                <input matInput required [formControl]="customerControls.lastName">
                <button *ngIf="customerControls.lastName.value" type="button" matSuffix mat-icon-button
                    aria-label="Clear" (click)="helperService.onClear($event, customerControls.lastName)">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>
        </div>
        <div class="inline-form-control flex-center">
            <mat-form-field appearance="fill">
                <mat-label translate>phone</mat-label>
                <input type="text" matInput maxlength="12" placeholder="000-000-0000"
                    [formControl]="customerControls.phoneNumber" (keydown)="phoneValidation($event)">
                <button *ngIf="customerControls.phoneNumber.value" type="button" matSuffix mat-icon-button
                    aria-label="Clear" (click)="helperService.onClear($event, customerControls.phoneNumber)">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label translate>email</mat-label>
                <input type="email" matInput [formControl]="customerControls.email">
                <button *ngIf="customerControls.email.value" type="button" matSuffix mat-icon-button aria-label="Clear"
                    (click)="helperService.onClear($event, customerControls.email)">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>
        </div>
    </div>
    <div class="mat-dialog-actions" mat-dialog-actions>
        <div class="full-width flex-between">
            <div class="action-buttons">
                <button mat-button type="submit" class="prm-btn fs-btn-txt save-btn" [disabled]="customerForm.invalid">
                    <span translate>save</span>
                </button>
            </div>
        </div>
    </div>
</form>