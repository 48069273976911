import { Component, Inject, OnInit } from '@angular/core';
import {
  FormControl,
  Validators,
  FormGroup,
  FormBuilder,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { CustomersService } from 'src/app/pages/content/customers/customers.service';
import { ModalType } from 'src/app/shared/models/Application.model';
import { CreateCustomerRequest } from 'src/app/shared/models/customer/create-customer-request.model';
import { CreateCustomerResponse } from 'src/app/shared/models/customer/create-customer-response.model';
import { CustomerResponse } from 'src/app/shared/models/customer/customer-response.model';
import { UpdateCustomerRequest } from 'src/app/shared/models/customer/update-customer-request.model';
import { HelperService } from 'src/app/shared/services/Helper.service';
import { RestApiService } from 'src/app/shared/services/RestApiServices.service';

const urlBase = '/Customer';

@Component({
  selector: 'app-customers-modal',
  templateUrl: './customers-modal.component.html',
  styleUrls: ['./customers-modal.component.scss'],
})
export class CustomersModalComponent implements OnInit {
  modalType: ModalType;
  loadUpdate: boolean;
  mainLoader: boolean;

  customerControls = {
    firstName: new FormControl('', [
      Validators.required,
      Validators.maxLength(100),
    ]),
    lastName: new FormControl('', [
      Validators.required,
      Validators.maxLength(100),
    ]),
    email: new FormControl('', [Validators.maxLength(320), Validators.email]),
    phoneNumber: new FormControl('', [
      Validators.maxLength(12),
      Validators.pattern(/^[1-9]\d{2}-\d{3}-\d{4}$/),
    ]),
  };
  customerForm: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CustomersModalComponent>,
    private formBuilder: FormBuilder,
    public helperService: HelperService,
    public customersServices: CustomersService,
    private restApiService: RestApiService,
    private translate: TranslateService
  ) {
    this.createForms();
  }

  createForms(): void {
    this.customerForm = this.formBuilder.group({ ...this.customerControls });
  }

  ngOnInit(): void {
    if (this.data.customer && this.data.customer.id > 0) {
      this.modalType = ModalType.Edit;
      this.customerForm.patchValue({ ...this.data.customer });
    } else {
      this.modalType = ModalType.Add;
    }
  }

  onSaveChanges(): void {
    this.loadUpdate = true;
    if (this.modalType === ModalType.Add) {
      let createRequest = new CreateCustomerRequest({
        ...this.customerForm.getRawValue(),
        companyId: this.restApiService.user?.company?.id,
        firstName: this.helperService.strUpperFirst(
          this.customerControls.firstName.value
        ),
        lastName: this.helperService.strUpperFirst(
          this.customerControls.lastName.value
        ),
        email: this.customerControls.email.value?.toLowerCase(),
      });
      this.customersServices
        .createItem(createRequest, urlBase)
        .subscribe((result) => {
          this.loadUpdate = false;
          if (result) {
            if (result.code > 0) {
              this.helperService.Notify(
                this.translate.instant('successChanges'),
                this.translate.instant('success'),
                3000
              );
              this.dialogRef.close(
                new CustomerResponse({
                  ...createRequest,
                  id: (result.data as CreateCustomerResponse)?.id,
                })
              );
              return;
            } else if (result.code === -2) {
              this.helperService.Notify(
                this.translate.instant('emailAlreadyExists'),
                this.translate.instant('failed'),
                2000
              );
              return;
            }
          }

          this.helperService.Notify(
            this.translate.instant('failedChanges'),
            this.translate.instant('failed'),
            2000
          );
        });
    } else {
      let updateRequest = new UpdateCustomerRequest({
        ...this.customerForm.getRawValue(),
        id: this.data.customer.id,
        companyId: this.restApiService.user?.company?.id,
        firstName: this.helperService.strUpperFirst(
          this.customerControls.firstName.value
        ),
        lastName: this.helperService.strUpperFirst(
          this.customerControls.lastName.value
        ),
        email: this.customerControls.email.value?.toLowerCase(),
      });
      this.customersServices
        .updateItem(updateRequest, urlBase)
        .subscribe((result) => {
          this.loadUpdate = false;

          if (result) {
            if (result.code > 0) {
              this.helperService.Notify(
                this.translate.instant('successChanges'),
                this.translate.instant('success'),
                3000
              );
              this.dialogRef.close(new CustomerResponse({ ...updateRequest }));
              return;
            } else if (result.code === -2) {
              this.helperService.Notify(
                this.translate.instant('emailAlreadyExists'),
                this.translate.instant('failed'),
                2000
              );
              return;
            }
          }

          this.helperService.Notify(
            this.translate.instant('failedChanges'),
            this.translate.instant('failed'),
            2000
          );
        });
    }
  }

  phoneValidation(event: KeyboardEvent): void {
    let el = <HTMLInputElement>event.target;

    if (
      el?.value.length &&
      event.key !== 'Backspace' &&
      event.key !== 'Delete'
    ) {
      if (el.value.length === 3 || el.value.length === 7) {
        this.customerControls.phoneNumber.setValue(el.value + '-');
      }
    }
  }
}
