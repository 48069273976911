import { ColorPalette } from 'src/app/shared/models/enums copy';
import { AlertModalType } from './alert-modal-type.enum';

export class AlertModalOption {
  type?: AlertModalType;
  title?: string;
  description?: any;
  showCloseButton?: boolean;
  color?: ColorPalette;
  buttonName?: string;
  role?: string;

  constructor(model: AlertModalOption) {
    this.type = model.type || AlertModalType.Info;
    this.title = model.title || '';
    this.description = model.description || '';
    this.showCloseButton = model.showCloseButton || true;
    this.color = model.color || ColorPalette.Info;
    this.buttonName = model.buttonName || 'OK';
    this.role = model.role || '';
  }
}
