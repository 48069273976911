<div class="header-bottonsheet">
    <img src="/assets/icons/bs-header-background.svg" alt="">
    <h3 class="upper-case">{{ translate.instant('notifications') }}</h3>
    <button mat-button class="close-btn" (click)="_bottomSheetRef.dismiss()">
        <img src="/assets/icons/modal-close.svg" alt="">
    </button>
</div>
<mat-list>
    <ng-container
        *ngIf="notificationService.getNotifications && notificationService.getNotifications.length; else emptyNotification">
        <div *ngFor="let notification of notificationService.getNotifications; let i = index" class="flex-between">
            <!-- Session Extension -->
            <mat-list-item matRipple *ngIf="notification.type == alertType.simulator">
                <span>{{ translate.instant('licenseFor') }}</span>
                <span> : {{ notification.simulator.name }}</span>
                <span>{{ translate.instant('hasExpired') }}</span>.
            </mat-list-item>
            <div class="flex-end" *ngIf="notification.type == alertType.simulator">
                <!--<button mat-button (click)="notificationService.onSimulator(notification.simulator )">Renew</button>-->
                <button mat-button (click)="openLicenseModal()">
                    <span>{{ translate.instant('renew') }}</span>
                </button>
            </div>
            <!-- Session Extension -->
            <mat-list-item matRipple *ngIf="notification.type == alertType.extendSession">
                <span>{{ notification.extendSession.eventName }} : </span>
                <span>{{ translate.instant('extra') }}</span>
                <span>{{
                    helperService.convertMinutesToFriendlyString(notification.extendSession.minutes) }} </span>
                <span>{{ translate.instant('requested') }}</span>
            </mat-list-item>
            <div class="action-buttons flex-end" *ngIf="notification.type == alertType.extendSession">
                <button mat-button (click)="notificationService.onReject(notification.extendSession)">
                    <span>{{ translate.instant('reject') }}</span>
                </button>
                <button mat-button (click)="notificationService.onApproved(notification.extendSession)">
                    <span>{{ translate.instant('approve') }}</span>
                </button>
            </div>
            <!-- Assistance -->
            <mat-list-item matRipple *ngIf="notification.type == alertType.assistance">
                <span>{{ notification.alertBody }}</span>
            </mat-list-item>
            <div class="flex-end" *ngIf="notification.type == alertType.assistance">
                <button mat-button (click)="notificationService.dismissAssistance(notification.assistance)">
                    <span>{{ translate.instant('dismiss') }}</span>
                </button>
            </div>
            <mat-divider *ngIf="i < notificationService.getNotifications.length - 1"></mat-divider>
        </div>
    </ng-container>
    <ng-template #emptyNotification>
        <p class="empty-list">{{ translate.instant('none') }}</p>
    </ng-template>
</mat-list>