import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-license-expire-modal',
  templateUrl: './license-expire-modal.component.html',
  styleUrls: ['./license-expire-modal.component.scss'],
})
export class LicenseExpireModalComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<LicenseExpireModalComponent>) {}

  ngOnInit(): void {}
}
