import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, lastValueFrom } from 'rxjs';
import { SignalType } from 'src/app/shared/models/Enums';
import {
  CreateEventRequest,
  CreateEventResponse,
  DeleteEventRequest,
  EventCalendarRequest,
  EventCalendarResponse,
  EventConflicted,
  EventResponse,
  ExtendSession,
  UpcomingEvents,
  UpdateEventRequest,
} from 'src/app/shared/models/Event.model';
import { LocationResponse } from 'src/app/shared/models/location.model';
import { SimulatorResponse } from 'src/app/shared/models/simulators.model';
import {
  Transaction,
  TransactionType,
} from 'src/app/shared/models/transaction';
import { HelperService } from 'src/app/shared/services/Helper.service';
import { WebSocketService } from 'src/app/shared/services/WebSocket.service';
import { HttpService } from 'src/app/shared/services/http.service';
import {
  getEventTime,
  getUTCDateFromOffset,
} from 'src/app/shared/time.functions';
import { environment } from 'src/environments/environment';

const urlBase = '/Event';

@Injectable({
  providedIn: 'root',
})
export class EventService extends HttpService<
  EventCalendarResponse,
  EventResponse,
  CreateEventRequest,
  boolean
> {
  private readonly _httpClient: HttpClient;

  private _isSessionActive: boolean;
  public get isSessionActive(): boolean {
    return this._isSessionActive;
  }
  public set isSessionActive(v: boolean) {
    this._isSessionActive = v;
  }

  private _upcomingEvents: EventResponse[];
  public get upcomingEvents(): EventResponse[] {
    return this._upcomingEvents;
  }
  public set upcomingEvents(events: EventResponse[]) {
    this._upcomingEvents = events;
  }

  private _conflictedEvents: EventConflicted[];
  public get getConflictedEvents(): EventConflicted[] {
    return this._conflictedEvents;
  }
  public set setConflictedEvents(events: EventConflicted[]) {
    this._conflictedEvents = events;
  }

  private _idGen: number;
  public get idGen(): number {
    return this._idGen;
  }
  public set idGen(v: number) {
    this._idGen = v;
  }

  private _today: Date;
  public get today(): Date {
    return this._today;
  }
  public set today(v: Date) {
    this._today = v;
  }

  constructor(
    httpClient: HttpClient,
    private helper: HelperService,
    public socketService: WebSocketService,
    private translate: TranslateService
  ) {
    super(httpClient);
    this._today = new Date();
    this._httpClient = httpClient;
    this._conflictedEvents = new Array<EventConflicted>();
    this._upcomingEvents = new Array<EventResponse>();
    this._idGen = -1;
    this._isSessionActive = false;
  }

  //------------------Events http requests---------
  public updateExtendedMinutes(data: ExtendSession): Observable<boolean> {
    const url = environment.apiServer + `/Event/UpdateExtendedMinutesAsync`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');

    return this._httpClient
      .post<boolean>(url, data, { headers: headers })
      .pipe((res) => {
        const p = res || null;
        return p;
      });
  }

  public updateEvents(data: any[]): Observable<boolean> {
    const url = environment.apiServer + `/Event/UpdateEventsAsync`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');

    return this._httpClient
      .post<boolean>(url, data, { headers: headers })
      .pipe((res) => {
        const p = res || null;
        return p;
      });
  }

  public GetAllConflictedEventsBySimulatorId(
    simId: number
  ): Observable<EventResponse[]> {
    const url =
      environment.apiServer +
      `/Event/GetAllConflictedEventsBySimulatorId/${simId}`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');

    return this._httpClient
      .get<EventResponse[]>(url, { headers: headers })
      .pipe((res) => {
        const p = res || null;
        return p;
      });
  }

  public getAllEventsAtRange(
    request: EventCalendarRequest
  ): Observable<EventCalendarResponse[]> {
    const url = environment.apiServer + `/Event/GetAllEventsAtRange`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');

    return this._httpClient
      .post<EventCalendarResponse[]>(url, request, {
        headers: headers,
      })
      .pipe((res) => {
        const p = res || null;
        return p;
      });
  }

  private getAllConflictedEventsBySimulatorId(
    simulatorId: number
  ): Observable<EventConflicted[]> {
    const url =
      environment.apiServer +
      `/Event/GetAllConflictedEventsBySimulatorId/${simulatorId}`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');

    return this._httpClient
      .get<EventConflicted[]>(url, {
        headers: headers,
      })
      .pipe((res) => {
        const p = res || null;
        return p;
      });
  }

  public getAllUpcomingEventsFromServer(
    companyId: number
  ): Observable<EventResponse[]> {
    const url =
      environment.apiServer + `/Event/GetAllUpcomingEvents/${companyId}`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');

    return this._httpClient
      .get<EventResponse[]>(url, { headers: headers })
      .pipe((res) => {
        const p = res || null;
        return p;
      });
  }
  //-----------------------------------------------

  //------------------Events request handlers---------
  public async createEventGlobal(
    event: EventResponse
  ): Promise<CreateEventResponse> {
    try {
      event.id = 0;
      event.startDateTime.setSeconds(0);

      let shouldSendSignalToLauncher =
        event.endDateTime <= new Date() ? false : true;

      const result: CreateEventResponse = await lastValueFrom(
        this.createItem(new CreateEventRequest({ ...event }), urlBase)
      );

      if (result && result.id > 0) {
        event.id = result.id;

        window.localStorage.removeItem(environment.bayManagerUser);

        if (shouldSendSignalToLauncher)
          this.socketService.sendSignal(
            event.simulatorIdentifier,
            SignalType.startSession,
            event.startDateTime.getTime(),
            event.endDateTime.getTime(),
            event.description,
            {
              'event-id': result.id.toString(),
              'event-name': event.name,
              'event-type': 'add',
            }
          );

        let transaction = new Transaction({
          eventId: result.id,
          transactionType: TransactionType.EventCreate,
          simulatorIdentifier: event.simulatorIdentifier,
          simulatorId: event.simulatorId,
          tabId: sessionStorage.getItem('BayManager-TabId'),
          locationId: event.location.id,
        });

        this.socketService.sendSignal(
          null,
          SignalType.Transaction,
          event.startDateTime.getTime(),
          event.endDateTime.getTime(),
          '',
          {
            transaction: JSON.stringify(transaction),
          }
        );

        this.pushInUpcoming(event);
      } else if (result.id < 0) {
        switch (result.id) {
          case -2:
            this.helper.Notify(
              `${this.translate.instant('timeConflictWithEvent')} : ${
                result.name
              }`,
              this.translate.instant('error'),
              3000
            );
            break;
          default:
            break;
        }
      }
      return result;
    } catch (err) {
      console.error(err);
      // this.helper.Notify(
      //   'Event ' +
      //     event.name +
      //     ' was not saved due to some error!, reason: ' +
      //     err,
      //     $localize`:@@error:ERROR`,
      //   3000
      // );
      this.helper.Notify(
        //'Event ' + event.name + ' was not saved due to some error',
        this.translate.instant('failedChanges'),
        this.translate.instant('error'),
        3000
      );
      return null;
    }
  }

  async createMockEvents(
    quantityEventsInOneDay: number,
    simulatorId: number,
    startFromDay: number = 0,
    quantityDays: number = 30,
    customerId?: number
  ): Promise<void> {
    try {
      //480 = (24hour * 60min) / (3min <- minimum life time for event and offset)
      if (quantityEventsInOneDay >= 480) {
        alert('ERROR. QuantityInDay param must be less than 480 !!!');
        return;
      }

      for (let day = 0; day < quantityDays; day++) {
        let offsetMin: number = 1;

        for (let index = 0; index < quantityEventsInOneDay; index++) {
          let startDate = new Date(),
            endDate = new Date();

          startDate.setDate(startDate.getDate() + startFromDay);
          startDate.setMinutes(startDate.getMinutes() + offsetMin);

          endDate.setDate(endDate.getDate() + startFromDay);
          endDate.setMinutes(endDate.getMinutes() + (offsetMin + 2));

          let event = new CreateEventRequest({
            description: `This is test description for event${index}.`,
            endDateTime: endDate,
            startDateTime: startDate,
            name: `event${index}-${day}`,
            simulatorId,
            customerId,
          });

          const result = await lastValueFrom(this.createItem(event, urlBase));

          console.log(`Event created by id : ${result.id}`);

          offsetMin += 3;
        }

        startFromDay++;
      }
    } catch (error) {
      console.error(error);
    }
  }

  public async updateEventGlobal(event: EventResponse): Promise<boolean> {
    try {
      let shouldSendSignalToLauncher =
        event.endDateTime <= new Date() ? false : true;

      window.localStorage.removeItem(environment.bayManagerUser);

      const result = await lastValueFrom(
        this.updateItem(new UpdateEventRequest({ ...event }), urlBase)
      );

      if (result) {
        if (shouldSendSignalToLauncher)
          this.socketService.sendSignal(
            event.simulatorIdentifier,
            SignalType.UpdateEvent,
            event.startDateTime.getTime(),
            event.endDateTime.getTime(),
            event.description,
            {
              'event-id': event.id.toString(),
              'event-name': event.name,
              'event-type': 'update',
            }
          );

        let transaction = new Transaction({
          eventId: event.id,
          transactionType: TransactionType.EventUpdate,
          simulatorIdentifier: event.simulatorIdentifier,
          tabId: sessionStorage.getItem('BayManager-TabId'),
        });

        this.socketService.sendSignal(
          null,
          SignalType.Transaction,
          event.startDateTime.getTime(),
          event.endDateTime.getTime(),
          '',
          {
            transaction: JSON.stringify(transaction),
          }
        );

        this.editFromUpcoming(event);
      } else {
        this.helper.Notify(
          this.translate.instant('timeConflictWithAnotherEvent'),
          this.translate.instant('error'),
          3000
        );
      }

      return result;
    } catch (err) {
      // this.helper.Notify(
      //   'Event ' + event.name + ' was not saved due to some error',
      //   'ERROR',
      //   3000
      // );
      this.helper.Notify(
        //'Event ' + event.name + ' was not saved due to some error',
        this.translate.instant('failedChanges'),
        this.translate.instant('error'),
        3000
      );
      console.error(err);
      return false;
    }
  }

  public async removeEventGlobal(
    event: EventResponse,
    sendSignal: boolean = true
  ): Promise<void> {
    try {
      if (sendSignal) {
        await lastValueFrom(
          this.removeItemFromBody(new DeleteEventRequest({ ...event }), urlBase)
        );
      }

      let shouldSendSignalToLauncher =
        event.endDateTime <= new Date() ? false : true;

      window.localStorage.removeItem(environment.bayManagerUser);

      if (sendSignal && shouldSendSignalToLauncher) {
        this.socketService.sendSignal(
          event.simulatorIdentifier,
          SignalType.endSession,
          event.startDateTime.getTime(),
          event.endDateTime.getTime(),
          '111',
          {
            'event-id': event.id.toString(),
            'event-name': event.name,
            'event-type': 'remove',
          }
        );
      }

      if (sendSignal) {
        let transaction = new Transaction({
          eventId: event.id,
          transactionType: TransactionType.EventCancel,
          simulatorIdentifier: event.simulatorIdentifier,
          simulatorId: event.simulatorId,
        });

        this.socketService.sendSignal(
          null,
          SignalType.Transaction,
          new Date().getTime(),
          new Date().getTime(),
          '',
          {
            transaction: JSON.stringify(transaction),
          }
        );
      }

      this.removeFromUpcoming(event.id);
    } catch (err) {
      // this.helper.Notify(
      //   'Event ' + event.name + ' was not deleted due to some error',
      //   'ERROR',
      //   3000
      // );
      this.helper.Notify(
        //'Event ' + event.name + ' was not saved due to some error',
        this.translate.instant('failedChanges'),
        this.translate.instant('error'),
        3000
      );
      console.error(err);
    }
  }

  public async setSessionActivate(
    eventId: number,
    event: EventResponse = null
  ): Promise<void> {
    try {
      if (!event || !event.id) {
        event = await lastValueFrom(this.getItemById(eventId, urlBase));
      }

      if (event) {
        let now = new Date();
        event.startDateTime = getUTCDateFromOffset(event.startDateTime);
        event.endDateTime = getUTCDateFromOffset(event.endDateTime);

        if (event.startDateTime < now && event.endDateTime > now) {
          console.log('selected session is active');
          this._isSessionActive = true;
        } else {
          this._isSessionActive = false;
        }
      }
    } catch (error) {
      this._isSessionActive = false;
      console.error(error);
    }
  }

  public async extendEventGlobal(
    event: ExtendSession,
    simulator: SimulatorResponse,
    sendExtendSignal: boolean = true
  ): Promise<boolean> {
    try {
      const result = await lastValueFrom(this.updateExtendedMinutes(event));
      window.localStorage.removeItem(environment.bayManagerUser);

      if (result) {
        // this.helper.Notify(
        //   'Event ' + simulator.currentEvent.name + ' was successfully saved',
        //   'SUCCESS',
        //   4000
        // );
        this.helper.Notify(
          //'Event ' + event.name + ' was successfully saved',
          this.translate.instant('successChanges'),
          this.translate.instant('success'),
          4000
        );

        if (sendExtendSignal) {
          let st = new Date(simulator.currentEvent.startDateTime).getTime(),
            et =
              new Date(simulator.currentEvent.endDateTime).getTime() +
              event.minutes * 60000;

          this.socketService.sendSignal(
            simulator.simulatorIdentifier,
            SignalType.UpdateEvent,
            st,
            et,
            'This is description',
            {
              'event-id': simulator.currentEvent.id.toString(),
              'event-name': simulator.currentEvent.name,
              'event-type': 'extend',
              'extend-minutes': event.minutes,
            }
          );

          let transaction = new Transaction({
            eventId: simulator.currentEvent.id,
            transactionType: TransactionType.EventExtend,
            simulatorIdentifier: simulator.currentEvent.simulatorIdentifier,
            tabId: sessionStorage.getItem('BayManager-TabId'),
            extendedMinutes: event.minutes,
          });

          this.socketService.sendSignal(
            null,
            SignalType.Transaction,
            simulator.currentEvent.startDateTime.getTime(),
            simulator.currentEvent.endDateTime.getTime(),
            '',
            {
              transaction: JSON.stringify(transaction),
            }
          );
        }

        this.extendMinuteFromUpcoming(event);
      }
      return result;
    } catch (err) {
      console.error(err);
      return false;
    }
  }
  //--------------------------------------------------

  //-------------------Helper Methods-------------------
  public showEventTime(date: Date): string {
    return getEventTime(date, 'hh:mm a').toUpperCase();
  }

  public pushInUpcoming(event: EventResponse): void {
    if (event.endDateTime > this.today) {
      this._upcomingEvents.push(new EventResponse({ ...event }));
    }
  }

  public editFromUpcoming(event: EventResponse): void {
    for (let i = 0; i < this._upcomingEvents.length; i++) {
      if (this._upcomingEvents[i].id === event.id) {
        this._upcomingEvents[i] = { ...event };
      }
    }
  }

  public extendMinuteFromUpcoming(event: ExtendSession): void {
    let extendedEvent = this._upcomingEvents.find(
      (f) => f.id === event.eventId
    );

    if (extendedEvent) {
      extendedEvent.endDateTime.setMinutes(
        extendedEvent.endDateTime.getMinutes() + event.minutes
      );
    }
  }

  public removeFromUpcoming(eventId: number): void {
    this._upcomingEvents = this.helper.removeElement(
      this._upcomingEvents,
      eventId
    );
  }

  public getEventByEventId(locations: LocationResponse[], eventId: number) {
    let event = null;
    locations.forEach((location) => {
      let sims = location.simulators;
      sims.forEach((sim) => {
        sim.events.forEach((eve) => {
          if (eve.id == eventId) event = event;
        });
      });
    });
    return event;
  }

  /**
   * Filtere event hayi ke hanooz timeshoon tamoom nashode va to saf hastan
   *
   * @param events
   * @returns
   */
  public filterEventsFromNowOn(events: EventResponse[]): EventResponse[] {
    return events.filter((f) => f.endDateTime > new Date());
  }

  /**
   * Convert stringify event to upcoming model event
   *
   * @param event
   * @returns
   */
  public jsonParseUpcomingEvent(event: string): UpcomingEvents {
    let jsonEvent = JSON.parse(event) as UpcomingEvents;
    jsonEvent.startDateTime = getUTCDateFromOffset(jsonEvent.startDateTime);
    jsonEvent.endDateTime = getUTCDateFromOffset(jsonEvent.endDateTime);
    return jsonEvent;
  }

  /**
   * Fetch all conflicted events from server by simulator id
   *
   * @param simulatorId
   */
  public loadAllConflictedEvents(simulatorId: number): void {
    this.getAllConflictedEventsBySimulatorId(simulatorId).subscribe(
      (conflictedEvents) => {
        this._conflictedEvents = new Array<EventConflicted>();
        if (conflictedEvents && conflictedEvents.length) {
          for (let index = 0; index < conflictedEvents.length; index++) {
            conflictedEvents[index].endDateTime = getUTCDateFromOffset(
              conflictedEvents[index].endDateTime
            );
            conflictedEvents[index].startDateTime = getUTCDateFromOffset(
              conflictedEvents[index].startDateTime
            );
          }
          this.setConflictedEvents =
            this.filterEventsFromNowOn(conflictedEvents);
        }
      }
    );
  }

  /**
   * Fetch all upcoming events from server by company id
   *
   * @param companyId
   */
  public async loadAllUpcomingEvents(companyId: number): Promise<void> {
    try {
      const events = await lastValueFrom(
        this.getAllUpcomingEventsFromServer(companyId)
      );
      this._upcomingEvents = this.getAndCalcUpcomingEvents(events);
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * Check events for has conflicted by another events
   *
   * @param requestEvent
   * @returns
   */
  public hasConflictedEvents(requestEvent: EventResponse): string {
    let conflictedEvent: string = '';

    if (!this._conflictedEvents || this._conflictedEvents.length == 0)
      return conflictedEvent;
    this._conflictedEvents.forEach((currentEvent) => {
      if (
        requestEvent.id !== currentEvent.id &&
        currentEvent.simulatorId == requestEvent.simulatorId
      ) {
        if (
          (currentEvent.startDateTime <= requestEvent.startDateTime &&
            currentEvent.endDateTime >= requestEvent.startDateTime) ||
          (currentEvent.startDateTime <= requestEvent.endDateTime &&
            currentEvent.endDateTime >= requestEvent.endDateTime)
        ) {
          return (conflictedEvent = currentEvent.name);
        } else if (
          (requestEvent.startDateTime <= currentEvent.startDateTime &&
            requestEvent.endDateTime >= currentEvent.startDateTime) ||
          (requestEvent.startDateTime <= currentEvent.endDateTime &&
            requestEvent.endDateTime >= currentEvent.endDateTime)
        ) {
          return (conflictedEvent = currentEvent.name);
        }
      }
      return conflictedEvent;
    });
    return conflictedEvent;
  }

  /**
   * Calculate and get all events from list
   *
   * @param events
   * @returns
   */
  public getAndCalcUpcomingEvents(events: EventResponse[]): EventResponse[] {
    if (events && events.length) {
      for (let index = 0; index < events.length; index++) {
        events[index].endDateTime = getUTCDateFromOffset(
          events[index].endDateTime
        );
        events[index].startDateTime = getUTCDateFromOffset(
          events[index].startDateTime
        );
      }
      events = this.filterEventsFromNowOn(events);
    }
    return events;
  }

  public eventDurationCalc(startDate: Date, endDate: Date): number {
    let utcSDate = getUTCDateFromOffset(startDate),
      utcEDate = getUTCDateFromOffset(endDate);

    utcSDate.setSeconds(0);
    utcEDate.setSeconds(0);

    let subtract = utcEDate.getTime() - utcSDate.getTime();
    return subtract / (1000 * 60);
  }
  //---------------------------------------------------
}
