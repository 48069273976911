import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ExtendSession } from 'src/app/shared/models/Event.model';
import { environment } from 'src/environments/environment';
import {
  LocationSelection,
  LocationWithSimulatorSelection,
} from 'src/app/shared/models/location.model';
import {
  SimulatorResponse,
  SimulatorSelectList,
} from 'src/app/shared/models/simulators.model';

@Injectable({
  providedIn: 'root',
})
export class SimulatorsService {
  constructor(private httpClient: HttpClient) {}

  public UpdateExtendedMinutes(data: ExtendSession): Observable<boolean> {
    const url = environment.apiServer + `/Event/UpdateExtendedMinutesAsync`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');

    return this.httpClient
      .post<boolean>(url, data, { headers: headers })
      .pipe((res) => {
        const p = res || null;
        return p;
      });
  }

  public getSimulatorsByLocationId(
    locationId: number
  ): Observable<SimulatorResponse[]> {
    const url =
      environment.apiServer +
      `/Simulator/GetSimulatorsByLocationId/${locationId}`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');

    return this.httpClient
      .get<SimulatorResponse[]>(url, { headers: headers })
      .pipe((res) => {
        const p = res || null;
        return p;
      });
  }

  public getLocationsSelectList(
    company_id: number
  ): Observable<LocationSelection[]> {
    const url =
      environment.apiServer + `/Locations/GetLocationsSelectList/${company_id}`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');

    return this.httpClient
      .get<LocationSelection[]>(url, { headers: headers })
      .pipe((res) => {
        const p = res || null;
        return p;
      });
  }

  public getSimulatorsSelectListByCompanyId(
    companyId: number
  ): Observable<SimulatorSelectList[]> {
    const url =
      environment.apiServer +
      `/Simulator/GetSimulatorsSelectListByCompanyId/${companyId}`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');

    return this.httpClient
      .get<SimulatorSelectList[]>(url, { headers: headers })
      .pipe((res) => {
        const p = res || null;
        return p;
      });
  }

  public getLocationsAndSimulatorsSelection(
    company_id: number
  ): Observable<LocationWithSimulatorSelection[]> {
    const url =
      environment.apiServer +
      `/Locations/GetLocationsAndSimulatorsSelection/${company_id}`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');

    return this.httpClient
      .get<LocationWithSimulatorSelection[]>(url, { headers: headers })
      .pipe((res) => {
        const p = res || null;
        return p;
      });
  }

  public getSimulatorByIdentifier(
    identifier: string
  ): Observable<SimulatorResponse> {
    const url =
      environment.apiServer +
      `/Simulator/GetSimulatorByIdentifier/${identifier}`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');

    return this.httpClient
      .get<SimulatorResponse>(url, { headers: headers })
      .pipe((res) => {
        const p = res || null;
        return p;
      });
  }

  public getSimulatorByRangeIdentifier(
    identifiers: string[]
  ): Observable<SimulatorResponse[]> {
    const url =
      environment.apiServer + `/Simulator/GetSimulatorByRangeIdentifier`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');

    return this.httpClient
      .post<SimulatorResponse[]>(url, identifiers, {
        headers: headers,
      })
      .pipe((res) => {
        const p = res || null;
        return p;
      });
  }
}
