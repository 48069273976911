import { Component, OnInit } from '@angular/core';
import { MiniLoaderService } from './mini-loader.service';

@Component({
  selector: 'mini-loader',
  templateUrl: './mini-loader.component.html',
  styleUrls: ['./mini-loader.component.scss'],
})
export class MiniLoaderComponent implements OnInit {
  constructor(public loaderService: MiniLoaderService) {}

  ngOnInit(): void {}
}
