import * as moment from 'moment';
import {
  UpcomingEvents,
  EventTimeBarUtility,
} from 'src/app/shared/models/Event.model';

export const offset = moment().utcOffset();

export const extendMinuteAndGetUTC = (date: Date, min: number): Date => {
  return moment.utc(date).add(min, 'minutes').toDate();
};

export const days = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export const getUTCDateFromOffset = (date: Date): Date => {
  return new Date(moment.utc(date).utcOffset(offset).format());
};

// export const getCurrentTimeZone = (): string => {
//   return moment.tz.guess();
// };

export const checkIsStartEvent = (event: UpcomingEvents): boolean => {
  return (
    new Date() ===
    new Date(moment.utc(event.startDateTime).utcOffset(offset).format())
  );
};

export const checkIsRuntimeEvent = (event: UpcomingEvents): boolean => {
  return (
    new Date() >=
    new Date(moment.utc(event.startDateTime).utcOffset(offset).format())
  );
};

export const checkIsFutureEvent = (event: UpcomingEvents): boolean => {
  return (
    new Date() <
    new Date(moment.utc(event.startDateTime).utcOffset(offset).format())
  );
};

export const isEventTimeOver = (event: UpcomingEvents): boolean => {
  let sTime = new Date(
    moment.utc(event.startDateTime).utcOffset(offset).format()
  );
  let eTime = new Date(
    moment.utc(event.endDateTime).utcOffset(offset).format()
  );

  if (new Date() >= new Date(sTime) && new Date() >= new Date(eTime)) {
    return true;
  }
  return false;
};

export const getDuration = (time1: any, time2: any): moment.Duration => {
  let timeDiff = moment(time1).diff(moment(time2));
  return moment.duration(timeDiff);
};

export const getDurationByMinutes = (time1: any, time2: any): string => {
  return `${getDuration(time1, time2).asMinutes()}m`;
};

export const getDurationByHours = (time1: any, time2: any): string => {
  let mins = getDuration(time1, time2).asMinutes();
  let hours = Math.floor(mins / 60);
  let result = '';

  if (hours > 0) {
    mins = mins - hours * 60;

    if (mins > 0) result = `${hours}h ${mins}m`;
    else result = `${hours}h`;
  } else {
    result = `${mins}m`;
  }

  return result;
};

// export const getDurationStringFormat = (time1: Date, time2: Date): moment.Duration => {
//   let duration = getDuration(time1, time2);
//   let strFormat = '';

//   let y = duration.asYears();
//   if (y > 0) strFormat += `${y}y `;

//   let m = duration.asMonths();
//   if (m > 0) strFormat += `${m}m`;

//   let w = duration.asWeeks();
//   if (w > 0) strFormat += `${w}w`;
// };

export const getEventTime = (time: Date, format: string): string => {
  return moment.utc(time).utcOffset(offset).format(format);
};

export const getHoursFromTimeFormat = (time: string): number => {
  if (time && time.length) {
    let hours = parseInt(time.split(':')[0]) ?? 0;
    return hours;
  }
  return 0;
};

export const getMinutesFromTimeFormat = (time: string): number => {
  if (time && time.length) {
    let minutes = parseInt(time.split(':')[1]) ?? 0;
    return minutes;
  }
  return 0;
};

/**
 * Get time as string or Date from argumant without am pm to
 * string time with am pm with moment.js
 *
 * @param time
 * @returns
 */
export const getTimeFromMoment = (time: string | Date = null): string => {
  if (time) {
    if (typeof time === 'string') {
      let newTime = new Date();
      newTime.setHours(getHoursFromTimeFormat(time));
      newTime.setMinutes(getMinutesFromTimeFormat(time));
      return moment(newTime).format('LT');
    }
    return moment(time).format('LT');
  }
  return moment().format('LT');
};

export const getMonthName = (time: Date) => {
  return time.toLocaleString('default', { month: 'long' });
};

export const getNameOfDay = (time: Date) => {
  let date = new Date(time);
  return days[date.getDay()];
};

export const getFirstDayOfWeekOffset = (time: Date) => {
  let date = new Date(time);
  let index: number = 0;

  while (getNameOfDay(date) !== days[0]) {
    date.setDate(date.getDate() - 1);
    index++;
  }

  return index;
};

export const decreaseDate = (time: Date, index: number) => {
  if (index < 0) return time;

  let date = new Date(time);

  while (index !== 0) {
    date.setDate(date.getDate() - 1);
    index--;
  }

  return date;
};

export const getTime = (date: Date): string => {
  let h = new Date(date).getHours();
  let m = new Date(date).getMinutes();
  return `${h < 10 ? '0' + h : h}:${m < 10 ? '0' + m : m}`;
};

export const isValidDate = (dateString: string | any): boolean => {
  let reg = /^\d{1,2}\/\d{1,2}\/\d{4}$/;
  // First check for the pattern
  dateString = (dateString as String).replace(/-/g, '/');

  if (!reg.test(dateString)) {
    let numArr = (dateString as String).split('/'),
      newDate = [];
    for (let index = numArr.length - 1; index >= 0; index--)
      newDate.push(numArr[index]);
    dateString = newDate.toString().replace(/,/g, '/');
    if (!reg.test(dateString)) return false;
  }

  // Parse the date parts to integers
  var parts = dateString.split('/');
  var day = parseInt(parts[1], 10);
  var month = parseInt(parts[0], 10);
  var year = parseInt(parts[2], 10);

  // Check the ranges of month and year
  if (year < 1000 || year > 3000 || month == 0 || month > 12) return false;

  var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  // Adjust for leap years
  if (year % 400 == 0 || (year % 100 != 0 && year % 4 == 0))
    monthLength[1] = 29;

  // Check the range of the day
  return day > 0 && day <= monthLength[month - 1];
};

export const convertMinutesToFriendlyString = (mins: number): string => {
  if (mins >= 60 * 24) return '';

  let h = Math.floor(mins / 60),
    m = mins - h * 60;

  let strTime = '';
  let hasMinutes = m > 0;
  let hasHours = h > 0;

  if (hasHours)
    strTime += `${h} hour${h > 1 ? 's' : ''} ${
      hasMinutes ? '&' : ''
    }`;

  if (hasMinutes)
    strTime += ` ${m} minute${m > 1 ? 's' : ''}`;

  return strTime;
};

export const set24TimeFormat = (time: string): string => {
  if (time && time.length) {
    let timeFormat: string = time
      .toLowerCase()
      .substring(time.length, time.length - 2);

    if (!timeFormat || !timeFormat.length) return time;

    let fixedTime = '';
    let hour = parseInt(time.split(':')[0]),
      min = parseInt(time.split(':')[1]);

    if (timeFormat === 'pm') {
      time = time.toLowerCase().replace('pm', '').trim();
      switch (hour) {
        case 1:
          fixedTime += 13;
          break;
        case 2:
          fixedTime += 14;
          break;
        case 3:
          fixedTime += 15;
          break;
        case 4:
          fixedTime += 16;
          break;
        case 5:
          fixedTime += 17;
          break;
        case 6:
          fixedTime += 18;
          break;
        case 7:
          fixedTime += 19;
          break;
        case 8:
          fixedTime += 20;
          break;
        case 9:
          fixedTime += 21;
          break;
        case 10:
          fixedTime += 22;
          break;
        case 11:
          fixedTime += 23;
          break;
        case 12:
          fixedTime += 12;
          break;
        default:
          break;
      }

      fixedTime += ':' + min;
      return fixedTime;
    } else if (timeFormat === 'am') {
      time = time.toLowerCase().replace('am', '').trim();
      if (hour == 12) hour = 0;
      return hour + ':' + min;
    }
  }

  return time;
};

export const getDateOnly = (date: Date): string => {
  let year = date.getFullYear();
  let month = `${
    date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
  }`;
  let day = `${date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}`;
  return year + '-' + month + '-' + day;
};

export const formatDate = (date: any): any => {
  let d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
};

//Date format for mobile browser
export const formatDate2 = (dateInput: string | any): Date => {
  if (dateInput && dateInput.length) {
    dateInput = (dateInput as String).replace(/\//g, '-');

    let dateWS = dateInput;
    let date = dateWS.split(' ')[0].split('-');
    let time = dateWS.split(' ')[1]?.split(':') ?? null;

    return new Date(
      +date[0],
      +date[1] - 1,
      +date[2],
      time[0] && time[0].length ? +time[0] : null,
      time[1] && time[1].length ? +time[1] : null
    );
  }
  return null;
};

export const convertMillisecondsToDigitalClock = (ms: number) => {
  let hours = Math.floor(ms / 3600000); // 1 Hour = 36000 Milliseconds
  let minutes = Math.floor((ms % 3600000) / 60000); // 1 Minutes = 60000 Milliseconds
  let seconds = Math.floor(((ms % 360000) % 60000) / 1000); // 1 Second = 1000 Milliseconds
  return {
    hours: hours,
    minutes: minutes,
    seconds: seconds,
    clock: hours + ':' + minutes + ':' + seconds,
  };
};

export const viewNormalTimeFormat = (
  hour: number,
  minute: number,
  second: number
): string => {
  return (
    (hour < 10 ? '0' + hour : hour.toString()) +
    ':' +
    (minute < 10 ? '0' + minute : minute.toString()) +
    ':' +
    (second < 10 ? '0' + second : second.toString())
  );
};

export const calculateTimeRemaining = (
  startDate: Date,
  endDate: Date
): EventTimeBarUtility => {
  let totalDuration = getDuration(endDate, startDate),
    currentDuration = getDuration(moment(), startDate),
    eventTimeBar = new EventTimeBarUtility({});
  let timeLine = totalDuration.asMilliseconds();
  let currentTime = currentDuration.asMilliseconds();

  eventTimeBar.d = timeLine / 100;
  eventTimeBar.duration =
    currentTime > 0 ? 1 * Math.floor(currentTime / eventTimeBar.d) : 0;
  eventTimeBar.durationLeft = timeLine - currentTime;
  eventTimeBar.left = moment.duration(
    eventTimeBar.durationLeft,
    'milliseconds'
  );
  eventTimeBar.showTime = viewNormalTimeFormat(
    eventTimeBar.left.hours(),
    eventTimeBar.left.minutes(),
    eventTimeBar.left.seconds()
  );

  return eventTimeBar;
};

export const sleep = (delay: number) =>
  new Promise((resolve) => setTimeout(resolve, delay));

export const intialValueInterval = async (
  callbackValue: () => {},
  delay: number = 100
): Promise<any> => {
  while (typeof callbackValue() === 'undefined' || callbackValue() === null)
    await sleep(delay);

  return callbackValue();
};
