export const environment = {
  production: true,
  wsEndpoint: 'wss://cpp8c86x71.execute-api.us-west-2.amazonaws.com/production',
  awsAPIURL:
    'https://32zlw20y67.execute-api.us-west-2.amazonaws.com/dev/bay-manager-explorer',
  apiServer: 'https://devlauncher.fsgapi.net/api/fs',
  hostName: 'devbaymanager.fsgapi.net',
  appConfigPath: './assets/config/app-config.json',
  defaultPage: 'event',

  //Local strorage values
  navName: '_fs_nav_name',
  bayManagerUser: 'Bay-Manager-User',
  calendarState: '_fs_fc_state',

  langId: '_fs_lang_id',
  defaultLanguage: 'en',
  defaultFontFamily: 'DavisSans',

  breakPointScreen: {
    small: 576,
    Medium: 768,
    large: 992,
    extraLarge: 1200,
    xExtraLarge: 1400,
  },
};
