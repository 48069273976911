<div class="header-bottonsheet">
    <img src="/assets/icons/bs-header-background.svg" alt="">
    <h3>LOGS SHEET</h3>
    <button mat-button class="close-btn" type="button" (click)="_bottomSheetRef.dismiss()">
        <img src="/assets/icons/modal-close.svg" alt="">
    </button>
    <button style="margin-inline-end: 3rem;" mat-button class="close-btn" type="button" (click)="logsSheetService.removeAllLogs()">Remove All</button>
</div>
<mat-list>
    <ng-container *ngIf="logsSheetService.getLogs && logsSheetService.getLogs.length; else emptyLogs">
        <div *ngFor="let log of logsSheetService.getLogs" class="flex-between">
            <mat-list-item matRipple>
                <div class="bottom-sheet-item"
                    [ngClass]="log.type === logType.Info ? 'info' : log.type === logType.Warning ? 'warning' : 'error'">
                    <b>{{ printLogType(log.type) }}</b>
                    <p>{{ log.message }}</p>
                    <button mat-button type="button" (click)="removeLog(log.id)">delete</button>
                </div>
            </mat-list-item>
        </div>
    </ng-container>
    <ng-template #emptyLogs>
        <p class="empty-list">None</p>
    </ng-template>
</mat-list>