import { ClientType, SignalType } from './Enums';

export class Signal {
  signalType?: SignalType;
  userId?: string;
  connectionId?: string;
  message?: string;
  simId?: string;
  type?: ClientType;
  status?: SignalType;
  startTime?:number
  endTime?: number
  extraParams?: Record<string,string>
  constructor(model: Signal) {
    this.signalType = model.signalType ;
    this.status = model.status;
    this.userId = model.userId || '';
    this.connectionId = model.connectionId || '';
    this.message = model.message || '';
    this.simId = model.simId || '';
    this.startTime = model.startTime;
    this.endTime = model.endTime;
    this.extraParams = model.extraParams || null
  }
}
