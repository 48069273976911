import { Injectable } from '@angular/core';
import { BreadCrumb, BreadCrumbPath } from './breadcrumb.model';

@Injectable({
  providedIn: 'root',
})
export class BreadCrumbService {
  private _breadCrumb: BreadCrumb;
  public get getBreadCrumb(): BreadCrumb {
    return this._breadCrumb;
  }
  public set setBreadCrumb(value: BreadCrumb) {
    this._breadCrumb = value;
  }

  constructor() {
    this._breadCrumb = new BreadCrumb({});
  }

  public addBreadCrumb(value: BreadCrumbPath): void {
    this._breadCrumb.path.unshift(value);
  }

  public removeBreadCrumb(): void {
    this._breadCrumb.path.shift();
  }
}
