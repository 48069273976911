export class ExtendSession {
  minutes?: number;
  eventId: number;
  eventName? :string
  userId?: string;
  simId?: string;
  approved?: boolean;

  constructor(model: ExtendSession) {
    this.minutes = model.minutes || null;
    this.eventId = model.eventId || null;
    this.eventName = model.eventName || null
    this.userId = model.userId || null;
    this.simId = model.simId || null;
    this.approved = model.approved || null;
  }
}
