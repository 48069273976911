import { EventResponse, UpcomingEvents } from './Event.model';
import { LicenseResponse } from './license.model';

export class SimulatorResponse {
  id?: number;
  name?: string;
  simulatorIdentifier?: string;
  teamviewerId?: string;
  salesforceId?: string;
  isOnline?: boolean;
  connectionId?: string;
  events?: EventResponse[];
  licenses?: LicenseResponse[];
  currentEvent?: UpcomingEvents;
  isExpired?: boolean;

  constructor(model: SimulatorResponse) {
    this.id = model.id || 0;
    this.name = model.name || '';
    this.simulatorIdentifier = model.simulatorIdentifier || '';
    this.teamviewerId = model.teamviewerId || '';
    this.salesforceId = model.salesforceId || '';
    this.connectionId = model.connectionId || '';
    this.isOnline = model.isOnline || false;
    this.events = model.events || (null as any);
    this.currentEvent = model.currentEvent || (null as any);
    this.licenses = model.licenses || (null as Array<LicenseResponse>);
    this.isExpired = model.isExpired || false;
  }
}

export class CreateSimulatorRequest {
  name?: string;
  simulatorIdentifier?: string;
  teamviewerId?: string;
  salesforceId?: string;

  constructor(model: CreateSimulatorRequest) {
    this.name = model.name || '';
    this.simulatorIdentifier = model.simulatorIdentifier || '';
    this.teamviewerId = model.teamviewerId || '';
    this.salesforceId = model.salesforceId || '';
  }
}

export class UpdateSimulatorRequest {
  id?: number;
  name?: string;
  simulatorIdentifier?: string;
  teamviewerId?: string;
  salesforceId?: string;
  isDelete?: boolean;
  isActive?: boolean;

  constructor(model: UpdateSimulatorRequest) {
    this.id = model.id || 0;
    this.name = model.name || '';
    this.simulatorIdentifier = model.simulatorIdentifier || '';
    this.teamviewerId = model.teamviewerId || '';
    this.salesforceId = model.salesforceId || '';
    this.isDelete = model.isDelete || false;
    this.isActive = model.isActive || true;
  }
}

export class UpdateSimulatorEditable extends UpdateSimulatorRequest {
  isNew?: boolean;
  isEditMode?: boolean;
  isEdited?: boolean;

  constructor(model: UpdateSimulatorEditable) {
    super(model);

    this.isNew = model.isNew || false;
    this.isEditMode = model.isEditMode || false;
    this.isEdited = model.isEdited || false;
  }
}

export class SimulatorSelectList {
  id?: number;
  simulatorIdentifier?: string;
  name?: string;
  isExpired?: boolean;
  locationId?: number;

  constructor(model: SimulatorSelectList) {
    this.id = model.id || 0;
    this.simulatorIdentifier = model.simulatorIdentifier || '';
    this.name = model.name || '';
    this.isExpired = model.isExpired;
    this.locationId = model.locationId || 0;
  }
}