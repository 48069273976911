import { Component, OnInit } from '@angular/core';
import {
  AuthService,
  ValidationStatus,
} from '../../shared/services/AuthService';
import { ActivatedRoute, Router } from '@angular/router';
import { HelperService } from 'src/app/shared/services/Helper.service';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { WebSocketService } from 'src/app/shared/services/WebSocket.service';
import { environment } from 'src/environments/environment';
import { RestApiService } from 'src/app/shared/services/RestApiServices.service';
import { NotificationService } from 'src/app/shared/modules/notification/notification.service';
import { EventService } from 'src/app/shared/services/event.service';
import { EventWorkerService } from 'src/app/shared/services/worker/event-worker/event-worker.service';
import { TranslateService } from '@ngx-translate/core';
import { sleep } from 'src/app/shared/time.functions';
import { lastValueFrom } from 'rxjs';

// code=218368&email=ba5f39f0-6dba-492b-bf8b-c4c6e617777f
// mohitzadeh@gmail.com

export enum AuthPageMode {
  Login = 1,
  ForgotPassword = 2,
  ResetPassword = 3,
}

@Component({
  selector: 'auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
})
export class AuthComponent implements OnInit {
  loader: boolean;
  showLoginPage: boolean;
  showError: boolean;
  showErrorResetPassword: boolean;
  authPageMode: AuthPageMode;
  showCompletePassword = false;
  passwordVisible: boolean;
  isAuth: boolean;
  showResetPasswordContent: boolean;

  isPasswordLawerCase: boolean;
  isPasswordUpperCase: boolean;
  isPasswordHasNumber: boolean;
  isPasswordHasSpecialCharacter: boolean;
  isPasswordUpTo8Characters: boolean;
  isMatchPasswords: boolean;

  email = new FormControl('', [Validators.required, Validators.email]);
  password = new FormControl('', [
    Validators.required,
    Validators.maxLength(50),
  ]);
  loginForm: FormGroup;

  emailF = new FormControl('', [Validators.required, Validators.email]);
  forgotPasswordForm: FormGroup;

  codeR = new FormControl('', []);
  newPasswordR = new FormControl('', [
    Validators.required,
    Validators.maxLength(50),
  ]);
  againPasswordR = new FormControl('', [
    Validators.required,
    Validators.maxLength(50),
  ]);
  passwordResetForm: FormGroup;

  constructor(
    private builder: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    public helperService: HelperService,
    public socketService: WebSocketService,
    private restApiService: RestApiService,
    private notificationService: NotificationService,
    private eventService: EventService,
    private eventWorkerService: EventWorkerService,
    private translate: TranslateService
  ) {
    this.loader =
      this.showError =
      this.isPasswordHasNumber =
      this.isPasswordHasSpecialCharacter =
      this.isPasswordLawerCase =
      this.isPasswordUpperCase =
      this.isPasswordUpTo8Characters =
      this.isMatchPasswords =
      this.showErrorResetPassword =
        false;
    this.passwordVisible = true;
    this.showLoginPage = this.isAuth = false;
    this.createForms();
    this.changeMode(AuthPageMode.Login);
    this.showResetPasswordContent = false;
  }

  createForms(): void {
    this.loginForm = this.builder.group({
      email: this.email,
      password: this.password,
    });
    this.forgotPasswordForm = this.builder.group({
      emailF: this.emailF,
    });
    this.passwordResetForm = this.builder.group({
      codeR: this.codeR,
      newPasswordR: this.newPasswordR,
      againPasswordR: this.againPasswordR,
    });
  }

  // @HostListener('window:popstate', ['$event'])
  // onPopState(event: Event) {
  //   if (!this.isAuth) {
  //     this.authService.setValidationResult = ValidationStatus.Invalid;
  //   }
  // }

  // ngAfterViewInit(): void {
  //   this.authService.isAuthenticated().then((isSignedIn) => {
  //     if (isSignedIn) {
  //       this.router.navigate([environment.defaultPage]).then(() => {
  //         this.authService.setValidationResult = ValidationStatus.Valid;
  //       });
  //     } else {
  //       this.authService.setValidationResult = ValidationStatus.Auth;
  //     }
  //   });
  // }

  async ngOnInit(): Promise<void> {
    try {
      if (this.authService.validationResult === ValidationStatus.Valid) {
        this.router.navigate([environment.defaultPage]);
      } else {
        this.showLoginPage = true;

        this.route.queryParams.subscribe((param) => {
          let email: string = param['email'] ?? '',
            code: string = param['code'] ?? '';

          if (email.indexOf(' ') > -1) {
            email = email.replace(/ /g, '+');
          }

          if (email?.length && code?.length) {
            this.emailF.setValue(email);
            this.codeR.setValue(code);
            this.authPageMode = AuthPageMode.ResetPassword;
            this.showResetPasswordContent = true;
          }
        });
      }
    } catch (err) {
      console.log('Is authenticate invalid', err);
      await this.authService.unautorized();
      return err;
    }
  }

  async onLogin(): Promise<void> {
    this.loader = true;

    let userName = this.loginForm.value.email.toLowerCase(); //'Mohitzadeh@gmail.com';
    let password = this.loginForm.value.password; //'Aa12345!';

    try {
      let res = await this.authService.signIn(userName, password);
      this.loader = false;

      if (res == 1 || res == -1) {
        const isAuth = await this.authService.isAuthenticatedCognitoGroups();

        if (!isAuth) {
          this.helperService.Notify(
            this.translate.instant('userNotBMAdmin'),
            this.translate.instant('error'),
            1500
          );
          await sleep(1500);
          await this.authService.signOut();
          return;
        }
      }

      if (res == 1) {
        let userId = await this.authService.getUserId();

        if (!userId?.length) {
          this.helperService.Notify(
            this.translate.instant('failed'),
            this.translate.instant('loginError'),
            3000
          );

          await this.authService.unautorized();
          return;
        }

        const user = await lastValueFrom(
          this.restApiService.getUserByUserId(userId)
        );

        if (!user) {
          this.helperService.Notify(
            this.translate.instant('failed'),
            this.translate.instant('loginError'),
            3000
          );

          await this.authService.unautorized();
          return;
        }

        this.restApiService.user = user;

        this.restApiService.initialDBObjects();
        await this.socketService.openSocket();
        this.notificationService.requestAndShowPermission();
        await this.initialEvents(user.company.id);

        this.authService.validationResult = ValidationStatus.Valid;

        console.log('auth: ', res);

        this.router.navigate([environment.defaultPage]);
      } else if (res == 0 || res == -2) {
        this.showError = true;
        this.helperService.Notify(
          this.translate.instant('failed'),
          this.translate.instant('loginError'),
          3000
        );

        console.log('auth failed: ', res);
        await this.authService.unautorized();
      } else if (res == -1) {
        this.changeMode(AuthPageMode.ResetPassword);

        this.showResetPasswordContent = true;
        this.showCompletePassword = true;
      }
    } catch (err) {
      console.log('Current user info failed to fetch', err);
      this.helperService.Notify(
        this.translate.instant('failed'),
        this.translate.instant('serverError'),
        3000
      );
      await this.authService.unautorized();
      return err;
    }
  }

  async onForgotPassword(): Promise<void> {
    try {
      this.loader = true;

      const isAuth = await this.authService.isAuthenticatedCognitoGroups();

      if (!isAuth) {
        this.helperService.Notify(
          this.translate.instant('userNotBMAdmin'),
          this.translate.instant('error'),
          1500
        );
        await sleep(1500);
        await this.authService.signOut();
        return;
      }

      let userName = this.forgotPasswordForm.value.emailF;

      await this.authService.forgotPassword(userName);

      this.showResetPasswordContent = false;
      this.changeMode(AuthPageMode.ResetPassword);
      //this.clearValues();
    } catch (error) {
      await this.authService.unautorized();
    } finally {
      this.loader = false;
    }
  }

  clearValues(): void {
    setTimeout(() => {
      this.passwordResetForm.reset();
    }, 100);
  }

  onResetPassword(): void {
    this.loader = true;

    let code = this.passwordResetForm.value.codeR;
    let userName = this.forgotPasswordForm.value.emailF;
    let newPasswordR = this.passwordResetForm.value.newPasswordR;
    let againPasswordR = this.passwordResetForm.value.againPasswordR;

    if (this.showCompletePassword) {
      this.authService
        .completeNewPassword(
          this.email.value,
          this.password.value,
          newPasswordR
        )
        .then((res) => {
          if (res) {
            this.helperService.Notify(
              this.translate.instant('success'),
              this.translate.instant('passwordChanged'),
              3000
            );
            this.changeMode(AuthPageMode.Login);
            this.showCompletePassword = false;
            location.reload();
            // this.router.navigate(['dashboard']).then(() => {
            //   console.log('auth: ', res);
            //   this.authService.setValidationResult = ValidationStatus.Valid;
            // });
          } else {
            this.helperService.Notify(
              this.translate.instant('failed'),
              this.translate.instant('failedResetPassword'),
              3000
            );
            this.showErrorResetPassword = true;
          }
        });
    } else {
      this.authService
        .forgotPasswordSubmit(userName, code, newPasswordR)
        .then((res) => {
          this.loader = false;
          if (res == 'SUCCESS') {
            this.helperService.Notify(
              this.translate.instant('success'),
              this.translate.instant('passwordChanged'),
              3000
            );
            this.changeMode(AuthPageMode.Login);
          } else {
            this.helperService.Notify(
              this.translate.instant('failed'),
              this.translate.instant('failedResetPassword'),
              3000
            );
            this.showErrorResetPassword = true;
          }
        })
        .catch((err) => {
          console.log(err);
          this.showErrorResetPassword = true;
          this.helperService.Notify(
            this.translate.instant('failed'),
            this.translate.instant('failedResetPassword'),
            3000
          );
        });
    }
  }

  changeMode(mode: AuthPageMode): void {
    this.passwordVisible = true;
    this.authPageMode = mode;
    this.passwordResetForm.reset();
    this.showErrorResetPassword = false;
    this.resetValidations();
  }

  resetValidations(): void {
    this.isPasswordHasNumber =
      this.isPasswordHasSpecialCharacter =
      this.isPasswordLawerCase =
      this.isPasswordUpperCase =
      this.isPasswordUpTo8Characters =
      this.isMatchPasswords =
        false;
  }

  checkValidations(): void {
    this.isPasswordLawerCase = /[a-z]/.test(this.newPasswordR.value);
    this.isPasswordUpperCase = /[A-Z]/.test(this.newPasswordR.value);
    this.isPasswordHasNumber = /[0-9]/.test(this.newPasswordR.value);
    this.isPasswordHasSpecialCharacter =
      /[-+=!$%^&*()_|~`{}\[\]:\/;<>?,.@#'"]/.test(this.newPasswordR.value);
    this.isPasswordUpTo8Characters = this.newPasswordR.value?.length > 7;
    this.isMatchPasswords =
      this.againPasswordR.value === this.newPasswordR.value;
  }

  resetPasswordResultValidation(): boolean {
    return (
      !this.isMatchPasswords ||
      !this.passwordResetForm.valid ||
      !this.isPasswordHasNumber ||
      !this.isPasswordHasSpecialCharacter ||
      !this.isPasswordLawerCase ||
      !this.isPasswordUpperCase ||
      !this.isPasswordUpTo8Characters
    );
  }

  onChangeVisibility(el: HTMLInputElement): void {
    this.passwordVisible = !this.passwordVisible;

    if (this.passwordVisible) {
      el.type = 'password';
    } else {
      el.type = 'text';
    }
  }

  /**
   * Initial upcoming events and worker service
   *
   */
  async initialEvents(companyId: number): Promise<void> {
    try {
      await this.eventService.loadAllUpcomingEvents(companyId);
      this.eventWorkerService.resetWorker();
    } catch (error) {
      console.error(error);
    }
  }

  // keyPress(event: any, mode: string): void {
  //   if (mode === 'SignIn') {
  //     if (event.key === 'Enter') {
  //       if (this.loginForm.valid) {
  //         this.onLogin();
  //       }
  //     }
  //   }
  // }
}
