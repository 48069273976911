import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BreadcrumbComponent } from './breadcrumb.component';
import { BreadCrumbService } from './breadcrumb.service';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { MatIconModule } from '@angular/material/icon';
import { CustomPipesModule } from '../../pipes/custome-pipes.module';

@NgModule({
  declarations: [BreadcrumbComponent],
  imports: [CommonModule, AppRoutingModule, MatIconModule, CustomPipesModule],
  exports: [BreadcrumbComponent],
  providers: [BreadCrumbService],
})
export class BreadcrumbModule {}
