export enum SignalType {
  LockDown,
  Unlock,
  startSession,
  endSession,
  Connected,
  Disconnected,
  SignalReceived,
  Error,
  RemoveEvent,
  UpdateEvent,
  Unknown,
  Ping,
  Pong,
  Transaction,
  Reconnected,
  RollBack
}

export enum AssistanceType{
  help
}

export enum ClientType {
  NotProvided,
  AdminPortal,
  SimulatorTerminal,
}

export enum SelectFilterType {
  Customer,
  Location,
  Simulator,
  Date
}