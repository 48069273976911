export enum LogType {
  Info = 0,
  Warning = 1,
  Error = 2,
}

export class LogResponse {
  id?: number;
  type?: LogType;
  message?: string;

  constructor(model: LogResponse) {
    this.id = model.id || 0;
    this.type = model.type || LogType.Info;
    this.message = model.message || '';
  }
}

export class LogRequest {
  type?: LogType;
  message?: string;

  constructor(model: LogResponse) {
    this.type = model.type || LogType.Info;
    this.message = model.message || '';
  }
}
