export class BreadCrumb {
  path?: BreadCrumbPath[];
  icon?: string;

  constructor(model: BreadCrumb) {
    this.path = model.path || new Array<BreadCrumbPath>();
    this.icon = model.icon || '';
  }
}

export class BreadCrumbPath {
  title?: string;
  route?: string;
  depth?: number;

  constructor(model: BreadCrumbPath) {
    this.title = model.title || '';
    this.route = model.route || '';
    this.depth = model.depth || 0;
  }
}
