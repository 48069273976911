import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/shared/services/AuthService';
import { Router } from '@angular/router';
import {
  NavigationBarItem,
  QuickAccessResponse,
} from 'src/app/shared/models/Application.model';
import { FlatTreeControl } from '@angular/cdk/tree';
import {
  MatTreeFlatDataSource,
  MatTreeFlattener,
} from '@angular/material/tree';
import { BreadCrumbService } from 'src/app/shared/modules/breadcrumb/breadcrumb.service';
import { WebSocketService } from 'src/app/shared/services/WebSocket.service';
import { EventResponse } from 'src/app/shared/models/Event.model';
import { RestApiService } from 'src/app/shared/services/RestApiServices.service';
import { NotificationService } from 'src/app/shared/modules/notification/notification.service';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { NotificationComponent } from 'src/app/shared/modules/notification/notification.component';
import { MatDialog } from '@angular/material/dialog';
import { VersionModalComponent } from '../version-modal/version-modal.component';
import { MODAL_DATA, NavItems } from 'src/app/shared/staticObjects';
import { NavigationBarService } from './nav-bar.service';
import { environment } from 'src/environments/environment';
import { LogsSheetComponent } from 'src/app/shared/modules/logs-sheet/logs-sheet.component';
import { HelperService } from 'src/app/shared/services/Helper.service';
import { EventCommonService } from 'src/app/pages/content/event/event-common.service';
import { LocaleService } from 'src/app/shared/services/locale.service';

@Component({
  selector: 'nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
})
export class NavBarComponent implements OnInit {
  @ViewChild('navRoutes') navRoutes: ElementRef<HTMLElement>;

  transformer = (node: NavigationBarItem, level: number) => {
    return {
      id: node.id,
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      level: level,
      route: node.route,
      activate: node.activate,
      children: node.children,
    };
  };
  treeControl = new FlatTreeControl<NavigationBarItem>(
    (node) => node.level,
    (node) => node.expandable
  );
  treeFlattener = new MatTreeFlattener(
    this.transformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.children
  );
  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  hasChild = (_: number, node: NavigationBarItem) => node.expandable;

  email: string = '';
  currentRoute: string;
  navRouteToggle: boolean;
  navLoader: boolean;
  isMobileScreen: boolean;
  modalObjects = MODAL_DATA;

  navItems: NavigationBarItem[];
  navTitleArr: string[];
  events: EventResponse[] = [];

  constructor(
    private authService: AuthService,
    private router: Router,
    private bcService: BreadCrumbService,
    private restApiService: RestApiService,
    private _bottomSheet: MatBottomSheet,
    public notificationService: NotificationService,
    private socketService: WebSocketService,
    private dialog: MatDialog,
    private navService: NavigationBarService,
    private helper: HelperService,
    private eventComService: EventCommonService,
    public locale: LocaleService
  ) {
    this.navLoader = true;
    this.navRouteToggle = false;
    this.navTitleArr = new Array<string>();
    this.navItems = new Array<NavigationBarItem>();
    this.navItems = NavItems;
    this.isMobileScreen =
      this.helper.screenSize() <= environment.breakPointScreen.Medium;

    this.socketService.sessionExtend$.subscribe((res) => {
      if (res) {
        this.notificationService.setNewNotification(res, null, null, true);
      }
    });
    this.socketService.Assistance$.subscribe((res) => {
      if (res) {
        this.notificationService.setNewNotification(null, res, null, true);
      }
    });
  }

  findIcon(name: string): string {
    switch (name) {
      case 'event':
        return '/assets/icons/bc-event.svg';
      case 'simulators':
        return '/assets/icons/bc-simulator2.svg';
      case 'staff':
        return '/assets/icons/bc-staff.svg';
      case 'customer':
        return '/assets/icons/bc-customer.svg';
      case 'archive':
        return '/assets/icons/bc-archive.svg';
      case 'integration':
        return '/assets/icons/bc-integration.svg';
      default:
        return '';
    }
  }

  setBreadcrumbValue(path: string, urlParam: string, name: string): void {
    this.bcService.setBreadCrumb = {
      icon: this.findIcon(name),
      path: [
        {
          title: path,
          route: `${path}/${urlParam}`,
          depth: 1,
        },
      ],
    };
  }

  ngOnInit(): void {
    // this.authService.isAuthenticated().then((isAuth) => {
    //   if (isAuth) {
    //     this.restApiService.currentUser.subscribe((user) => {
    //       if (user) {
    //         let navItemsTmp = NavItems;

    //         navItemsTmp = navItemsTmp.filter((f) => {
    //           if (user.isSuperUser) {
    //             return f;
    //           } else {
    //             return !f.forSuperUser;
    //           }
    //         });

    //         this.navItems = navItemsTmp;
    //         this.dataSource.data = navItemsTmp;
    //         this.setNavItemActivate(this.currentRoute);
    //         this.initialNavItemActive();

    //         this.navLoader = false;
    //       }
    //     });
    //   }
    // });

    let navItemsTmp = NavItems;

    navItemsTmp = navItemsTmp.filter((f) => {
      if (this.restApiService.user?.isSuperUser) {
        return f;
      } else {
        return !f.forSuperUser;
      }
    });

    this.navItems = navItemsTmp;
    this.dataSource.data = navItemsTmp;
    this.setNavItemActivate(this.currentRoute);
    this.initialNavItemActive();

    this.navLoader = false;

    document.querySelector('main').addEventListener('scroll', (e) => {
      if ((e.target as HTMLElement).scrollTop > 20) {
        document.querySelector('.top-nav').classList.add('top-nav-shadow');
      } else {
        document.querySelector('.top-nav').classList.remove('top-nav-shadow');
      }
    });
  }

  initialNavItemActive(): void {
    let routeName =
        window.localStorage.getItem(environment.navName) ??
        environment.defaultPage,
      url = window.location.pathname;

    if (url && url.length) {
      url = url.substring(1, url.length);
    }

    if (url.indexOf(routeName) === -1) {
      routeName = environment.defaultPage;
    }

    let routeObj = this.navItems.find((f) => f.name === routeName);

    this.setNavItemActivate(routeName);
    this.setBreadcrumbValue(routeObj.route, routeObj.urlParam, routeObj.name);

    if (routeObj.urlParam && routeObj.urlParam.length)
      routeName = `${routeObj.route}/${routeObj.name}`;
    else routeName = `/${routeObj.route}`;
  }

  onLogoutClick(): void {
    // window.localStorage.removeItem(environment.bayManagerUser);
    // await this.authService.signOut();
    // this.router.navigate(['/login'], { replaceUrl: true }).then(() => {
    //   this.authService.validationResult = ValidationStatus.Invalid;
    // });
    this.authService.unautorized().then((_) => {
      window.location.reload();
    });
  }

  changeRoute(route: string, urlParam: string, name: string): void {
    let currentUrl = window.location.pathname;
    if (currentUrl === `/${route}/${urlParam}`) return;

    let fullUrl = route;

    if (urlParam && urlParam.length) {
      this.navService.setUrlParam(urlParam);
      fullUrl = `${route}/${urlParam}`;
    } else {
      this.navService.setUrlParam(route);
      fullUrl = route;
    }

    this.setNavItemActivate(name);
    this.router.navigateByUrl(fullUrl);
    this.setBreadcrumbValue(route, urlParam, name);

    if (this.navRouteToggle) {
      this.toggleNavRoute();
    }
  }

  setNavItemActivate(route: string): void {
    if (route && route.length) {
      for (let index = 0; index < this.navItems.length; index++) {
        let item = this.navItems[index];
        item.route === route ? (item.activate = true) : (item.activate = false);
      }
      this.dataSource.data = this.navItems;
    }
  }

  toggleNavRoute(): void {
    this.navRouteToggle = !this.navRouteToggle;

    if (!this.isMobileScreen) {
      if (this.navRouteToggle) {
        this.navRoutes.nativeElement.style.left = '66px';
      } else {
        this.navRoutes.nativeElement.style.left = '-205px';
      }
    }
  }

  showNotifs(): void {
    this._bottomSheet
      .open(NotificationComponent, {
        backdropClass: 'background-blur',
      })
      .afterDismissed()
      .subscribe(() => {
        this.notificationService.notificationCounts = 0;
      });
  }

  showLogs(): void {
    this._bottomSheet.open(LogsSheetComponent, {
      backdropClass: 'background-blur',
    });
  }

  onOpenVerModal(): void {
    const dialogRef = this.dialog.open(VersionModalComponent, {
      width: '600px',
      backdropClass: 'background-blur',
    });

    dialogRef.afterClosed().subscribe(() => {});
  }

  async onClickQuickAccess(name: string): Promise<void> {
    const response = await this.eventComService.onOpenEventModal({
      walkIn: name === 'walkIn',
    });

    if (response) {
      this.navService.quickAccess$.next(
        new QuickAccessResponse({
          name,
          data: response,
        })
      );
    }

    this.navRouteToggle = false;
  }
}
