<div *ngIf="showLoginPage" class="main-content">
    <form *ngIf="authPageMode === 1" [formGroup]="loginForm" (ngSubmit)="onLogin()">
        <mat-card>
            <img mat-card-image src="/assets/images/auth-image.jpg" alt="Full Swing">
            <mat-card-content>
                <h3 translate>signInFormHeader</h3>
                <p *ngIf="showError" class="error-auth" translate>incorrectTextSignIn</p>
                <mat-form-field appearance="fill">
                    <mat-label translate>email</mat-label>
                    <input required autocomplete="email" matInput type="email" [formControl]="email">
                    <button *ngIf="email.value" matSuffix mat-icon-button aria-label="Clear" type="button"
                        (click)="helperService.onClear($event, email)">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label translate>password</mat-label>
                    <input #passwordTB autocomplete="new-password" required matInput type="password"
                        [formControl]="password">
                    <button *ngIf="password.value" matSuffix mat-icon-button aria-label="Clear" type="button"
                        (click)="onChangeVisibility(passwordTB)">
                        <mat-icon *ngIf="passwordTB.type === 'password'">visibility_off</mat-icon>
                        <mat-icon *ngIf="passwordTB.type === 'text'">visibility</mat-icon>
                    </button>
                </mat-form-field>
                <a (click)="authPageMode = 2; showError = false" translate>forgotPasswordLink</a>
            </mat-card-content>
            <mat-card-actions>
                <button mat-button type="submit" class="prm-btn loader-btn" [disabled]="!loginForm.valid || loader">
                    <div class="flex-center full-width">
                        <mat-spinner [diameter]="20" *ngIf="loader"></mat-spinner>
                        <span translate>signIn</span>
                    </div>
                </button>
            </mat-card-actions>
        </mat-card>
    </form>

    <form autocomplete="off" *ngIf="authPageMode === 2" [formGroup]="forgotPasswordForm"
        (ngSubmit)="onForgotPassword()">
        <mat-card>
            <img mat-card-image src="/assets/images/auth-image.jpg" alt="Full Swing">
            <mat-card-content>
                <h3 translate>forgotPasswordFormHeader</h3>
                <p translate>forgotPasswordFormDescription</p>
                <mat-form-field appearance="fill">
                    <mat-label translate>email</mat-label>
                    <input required matInput type="email" [formControl]="emailF">
                    <button *ngIf="emailF.value" matSuffix mat-icon-button aria-label="Clear" type="button"
                        (click)="helperService.onClear($event, emailF)">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>
                <a (click)="authPageMode = 1; showError = false" translate>gobackToSignInLink</a>
            </mat-card-content>
            <mat-card-actions>
                <button mat-button type="submit" class="prm-btn loader-btn"
                    [disabled]="!forgotPasswordForm.valid || loader">
                    <div class="flex-center full-width">
                        <mat-spinner [diameter]="20" *ngIf="loader"></mat-spinner>
                        <span translate>resetPassword</span>
                    </div>
                </button>
            </mat-card-actions>
        </mat-card>
    </form>

    <form auto-complete *ngIf="authPageMode === 3" [formGroup]="passwordResetForm" [excludes]="['codeR']"
        (ngSubmit)="onResetPassword()">
        <mat-card>
            <img mat-card-image src="/assets/images/auth-image.jpg" alt="Full Swing">
            <mat-card-content>
                <p class="pass-description" translate>resetPasswordFormDescription</p>

                <ng-container *ngIf="showResetPasswordContent">
                    <p *ngIf="showErrorResetPassword" class="error-auth" translate>resetPasswordFormFailed</p>
                    <mat-form-field *ngIf="!showCompletePassword" appearance="fill">
                        <mat-label translate>code</mat-label>
                        <input required matInput type="text" name="codeR" [formControl]="codeR">
                        <button *ngIf="codeR.value" matSuffix mat-icon-button aria-label="Clear" type="button"
                            (click)="helperService.onClear($event, codeR)">
                            <mat-icon>close</mat-icon>
                        </button>
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label translate>newPassword</mat-label>
                        <input #passwordTBNew required matInput type="password" name="newPasswordR"
                            [formControl]="newPasswordR" (keyup)="checkValidations()">
                        <button *ngIf="newPasswordR.value" matSuffix mat-icon-button aria-label="Clear" type="button"
                            (click)="onChangeVisibility(passwordTBNew)">
                            <mat-icon *ngIf="passwordTBNew.type === 'password'">visibility_off</mat-icon>
                            <mat-icon *ngIf="passwordTBNew.type === 'text'">visibility</mat-icon>
                        </button>
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label translate>enterNewPassword</mat-label>
                        <input #passwordTBAgain required matInput type="password" name="againPasswordR"
                            [formControl]="againPasswordR"
                            (keyup)="isMatchPasswords = againPasswordR.value === newPasswordR.value">
                        <button *ngIf="againPasswordR.value" matSuffix mat-icon-button aria-label="Clear" type="button"
                            (click)="onChangeVisibility(passwordTBAgain)">
                            <mat-icon *ngIf="passwordTBAgain.type === 'password'">visibility_off</mat-icon>
                            <mat-icon *ngIf="passwordTBAgain.type === 'text'">visibility</mat-icon>
                        </button>
                    </mat-form-field>
                    <ul>
                        <li [style.color]="isPasswordLawerCase ? 'green' : 'red'">
                            <mat-icon *ngIf="!isPasswordLawerCase">close</mat-icon>
                            <mat-icon *ngIf="isPasswordLawerCase">check</mat-icon>
                            <span translate>passwordInvalidLowerCase</span>
                        </li>
                        <li [style.color]="isPasswordUpperCase ? 'green' : 'red'">
                            <mat-icon *ngIf="!isPasswordUpperCase">close</mat-icon>
                            <mat-icon *ngIf="isPasswordUpperCase">check</mat-icon>
                            <span translate>passwordInvalidUpperCase</span>
                        </li>
                        <li [style.color]="isPasswordHasSpecialCharacter ? 'green' : 'red'">
                            <mat-icon *ngIf="!isPasswordHasSpecialCharacter">close</mat-icon>
                            <mat-icon *ngIf="isPasswordHasSpecialCharacter">check</mat-icon>
                            <span translate>passwordInvalidSpecialChar</span>
                        </li>
                        <li [style.color]="isPasswordHasNumber ? 'green' : 'red'">
                            <mat-icon *ngIf="!isPasswordHasNumber">close</mat-icon>
                            <mat-icon *ngIf="isPasswordHasNumber">check</mat-icon>
                            <span translate>passwordInvalidNumber</span>
                        </li>
                        <li [style.color]="isPasswordUpTo8Characters ? 'green' : 'red'">
                            <mat-icon *ngIf="!isPasswordUpTo8Characters">close</mat-icon>
                            <mat-icon *ngIf="isPasswordUpTo8Characters">check</mat-icon>
                            <span translate>passwordInvalid8CharLength</span>
                        </li>
                        <li [style.color]="isMatchPasswords ? 'green' : 'red'">
                            <mat-icon *ngIf="!isMatchPasswords">close</mat-icon>
                            <mat-icon *ngIf="isMatchPasswords">check</mat-icon>
                            <span translate>passwordInvalidMatch</span>
                        </li>
                    </ul>
                </ng-container>
            </mat-card-content>
            <mat-card-actions>
                <button *ngIf="showResetPasswordContent" mat-button type="submit" class="prm-btn loader-btn"
                    [disabled]="resetPasswordResultValidation() || loader">
                    <div class="flex-center full-width">
                        <mat-spinner [diameter]="20" *ngIf="loader"></mat-spinner>
                        <span translate>changePassword</span>
                    </div>
                </button>
            </mat-card-actions>
        </mat-card>
    </form>
</div>