import { AssistanceType } from './Enums';

export class Assistance {
  simId?: string;
  type?: AssistanceType;
  userId?: string;

  constructor(model: Assistance) {
    this.type = model.type;
    this.simId = model.simId || '';
    this.userId = model.userId || '';
  }
}
