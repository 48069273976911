import { getUTCDateFromOffset } from '../time.functions';
import { LocationResponse } from './location.model';
import { SimulatorResponse } from './simulators.model';
import { SignalType } from './Enums';

export enum EventModalMode {
  changed = 1,
  delete = 2,
  create = 3,
  cancelSession = 4,
}

export class EventShow {
  id?: number;
  customerId?: number;
  name?: string;
  description?: string;
  startTime?: string;
  endTime?: string;
  startDate?: string;
  endDate?: string;
  // selectedDate? : string
  simulatorId?: number;

  constructor(model: EventShow) {
    this.id = model.id || 0;
    this.customerId = model.customerId || (null as any);
    this.name = model.name || '';
    this.description = model.description || '';
    this.startTime = model.startTime || '';
    this.endTime = model.endTime || '';
    this.startDate = model.startDate || '';
    this.endDate = model.endDate || '';
    // this.selectedDate = model.selectedDate || '';
    this.simulatorId = model.simulatorId || 0;
  }
}

export class EventTimeBarUtility {
  d?: number;
  duration?: number;
  durationLeft?: number;
  left?: any;
  showTime?: string;

  constructor(model: EventTimeBarUtility) {
    this.d = model.d || 0;
    this.duration = model.duration || 0;
    this.durationLeft = model.durationLeft || 0;
    this.left = model.left || (null as any);
    this.showTime = model.showTime || '';
  }
}

export class EventResponse {
  id?: number;
  customerId?: number;
  name?: string;
  description?: string;
  startDateTime?: Date;
  endDateTime?: Date;
  simulatorId?: number;
  simulatorIdentifier?: string;
  simulatorName?: string;
  extendedMinutes?: number;
  location?: LocationResponse;
  locationName?: string;
  action?: SignalType
  eventType?: string
  timeZoneId?:string
  constructor(model: EventResponse) {
    this.id = model.id || 0;
    this.customerId = model.customerId || (null as any);
    this.name = model.name || '';
    this.simulatorIdentifier = model.simulatorIdentifier || '';
    this.simulatorName = model.simulatorName || '';
    this.description = model.description || '';
    this.startDateTime = model.startDateTime || (null as any);
    this.endDateTime = model.endDateTime || (null as any);
    this.simulatorId = model.simulatorId || 0;
    this.extendedMinutes = model.extendedMinutes || null;
    this.location = model.location || (null as any);
    this.locationName = model.locationName || '';
    this.timeZoneId = model.timeZoneId || null;
  }
}

export class UpcomingEvents extends EventResponse {
  startML?: number;
  endML?: number;
  eventTimeBar?: EventTimeBarUtility;

  constructor(model: UpcomingEvents) {
    super(model);

    this.startML = model.startML || 0;
    this.endML = model.endML || 0;
    this.eventTimeBar = model.eventTimeBar || (null as any);
  }
}

export class CreateEventRequest {
  id?: number;
  customerId?: number;
  name?: string;
  description?: string;
  startDateTime?: Date;
  endDateTime?: Date;
  simulatorId?: number;

  constructor(model: CreateEventRequest) {
    this.customerId = model.customerId || (null as any);
    this.name = model.name || '';
    this.description = model.description || '';
    this.startDateTime = model.startDateTime || (null as any);
    this.endDateTime = model.endDateTime || (null as any);
    this.simulatorId = model.simulatorId || 0;
  }
}

export class CreateEventResponse {
  id?: number;
  name?: string;
  description?: string;
  startDateTime?: Date;
  endDateTime?: Date;
  simulatorId?: number;

  constructor(model: CreateEventResponse) {
    this.id = model.id || 0;
  }
}

export class UpdateEventRequest {
  id?: number;
  customerId?: number;
  name?: string;
  description?: string;
  startDateTime?: Date;
  endDateTime?: Date;
  simulatorId?: number;
  externalId?: number

  constructor(model: UpdateEventRequest) {
    this.id = model.id || 0;
    this.customerId = model.customerId || (null as any);
    this.name = model.name || '';
    this.description = model.description || '';
    this.startDateTime = model.startDateTime || (null as any);
    this.endDateTime = model.endDateTime || (null as any);
    this.simulatorId = model.simulatorId || 0;
    this.externalId = model.externalId || 0;
  }
}

export class DeleteEventRequest {
  id?: number;
  simulatorIdentifier?: string;

  constructor(model: DeleteEventRequest) {
    this.id = model.id || 0;
    this.simulatorIdentifier = model.simulatorIdentifier || '';
  }
}

export class EventModalObjects {
  selectInfo?: any;
  event?: EventResponse;
  simulatorId?: number;
  selectedDateEvents?: EventResponse[];
  locations?: LocationResponse[];
  walkIn?: boolean;
  id?: number;
  calendarViewState?: string;
  startDateTime?: Date;
  endDateTime?: Date;
  //isSessionActive?: boolean;

  constructor(model: EventModalObjects) {
    this.selectInfo = model.selectInfo || (null as any);
    this.event = model.event || (null as any);
    this.selectedDateEvents =
      model.selectedDateEvents || (null as Array<EventResponse>);
    this.locations = model.locations || (null as Array<LocationResponse>);
    this.walkIn = model.walkIn || false;
    this.id = model.id || 0;
    this.simulatorId = model.simulatorId || 0;
    this.calendarViewState = model.calendarViewState || '';
    this.startDateTime = model.startDateTime || null;
    this.endDateTime = model.endDateTime || null;
    //this.isSessionActive = model.isSessionActive;
  }
}

export class EventModalResult {
  mode?: EventModalMode;
  event?: EventResponse;
  fromSimulator?: SimulatorResponse;
  fromLocation?: LocationResponse;

  constructor(model: EventModalResult) {
    this.mode = model.mode || EventModalMode.changed;
    this.event = model.event || (null as any);
    this.fromSimulator = model.fromSimulator || (null as any);
    this.fromLocation = model.fromLocation || (null as any);
  }
}

export class ExtendSession {
  simid?: string;
  simidNumber?: number;
  userId?: string;
  eventId?: number;
  minutes?: number;
  approved?: boolean;

  constructor(model: ExtendSession) {
    this.simid = model.simid || '';
    this.simidNumber = model.simidNumber || 0;
    this.userId = model.userId || '';
    this.eventId = model.eventId || 0;
    this.minutes = model.minutes || 0;
    this.approved = model.approved || (null as any);
  }
}

export enum EventsWorkerStatus {
  Start = 0,
  End = 1,
  ExtendSession = 2,
  Grow = 3,
}

export class EventsWorkerConfigObj {
  currentEventId?: number;
  minuteExtended?: number;
  status?: EventsWorkerStatus;
  events?: UpcomingEvents[];
  currentEvent?: UpcomingEvents;
  location?: LocationResponse;

  constructor(model: EventsWorkerConfigObj) {
    this.currentEventId = model.currentEventId || 0;
    this.minuteExtended = model.minuteExtended || 0;
    this.status = model.status || EventsWorkerStatus.Start;
    this.events = model.events || (null as any);
    this.currentEvent = model.currentEvent || (null as any);
    this.location = model.location || (null as any);
  }
}

//-------------------------------------------------------------

//---------------------new models------------------------------
export class EventCalendarResponse {
  id?: number;
  name?: string;
  startDateTime?: Date;
  endDateTime?: Date;
  simulatorId?: number;
  simulatorName?: string;
  simulatorIdentifier?: string;
  isOnline?: boolean;
  connectionId?: string;
  locationName?: string;
  eventTimeBar?: EventTimeBarUtility;

  constructor(model: EventCalendarResponse) {
    this.id = model.id || 0;
    this.name = model.name || '';
    this.startDateTime =
      getUTCDateFromOffset(model.startDateTime) || (null as any);
    this.endDateTime = getUTCDateFromOffset(model.endDateTime) || (null as any);
    this.simulatorIdentifier = model.simulatorIdentifier || '';
    this.simulatorId = model.simulatorId || 0;
    this.simulatorName = model.simulatorName || '';
    this.connectionId = model.connectionId || '';
    this.isOnline = model.isOnline || false;
    this.locationName = model.locationName || '';
    this.eventTimeBar = model.eventTimeBar || (null as any);
  }
}

export class EventCalendarRequest {
  companyId?: number;
  dateFrom?: Date;
  dateTo?: Date;

  constructor(model: EventCalendarRequest) {
    this.companyId = model.companyId || 0;
    this.dateFrom = model.dateFrom || (null as any);
    this.dateTo = model.dateTo || (null as any);
  }
}

export class EventConflicted {
  id?: number;
  name?: string;
  startDateTime?: Date;
  endDateTime?: Date;
  simulatorId?: number;

  constructor(model: EventConflicted) {
    this.id = model.id || 0;
    this.name = model.name || '';
    this.startDateTime = model.startDateTime || (null as any);
    this.endDateTime = model.endDateTime || (null as any);
    this.simulatorId = model.simulatorId || 0;
  }
}
//-------------------------------------------------------------
