<div class="dialog-main-content">
    <div class="header-modal-content">
        <div class="modal-icon"></div>
        <h1 class="text-overflow" mat-dialog-title [ngStyle]="{'color': data.option.color}">{{ data.option.title }}</h1>
        <button *ngIf="data.option.showCloseButton" type="button" mat-button class="close-btn"
            (click)="dialogRef.close()">
            <img src="/assets/icons/modal-close.svg" alt="">
        </button>
    </div>
    <div mat-dialog-content class="mat-dialog-content">
        <p [innerHTML]="data.option.description"></p>
    </div>
    <div mat-dialog-actions class="mat-dialog-actions">
        <div class="full-width flex-between">
            <div class="action-buttons">
                <button mat-button type="button" class="prm-btn fs-btn-txt save-btn" [mat-dialog-close]="returnKey()"
                    cdkFocusInitial>{{ data.option.buttonName }}</button>
            </div>
        </div>
    </div>
</div>