const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_B0NCikVLQ",
    "aws_user_pools_web_client_id": "5lqkbi3b9otvgkveqk0dne0mu",
    "oauth": {
        "domain": "bay-manager.auth.us-west-2.amazoncognito.com",
        "scope": [
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        // "redirectSignIn": "https://localhost:401",
        // "redirectSignOut": "https://localhost:401/login",
        "redirectSignIn": "https://devbaymanager.fsgapi.net/login/",
        "redirectSignOut": "https://devbaymanager.fsgapi.net/login",
        "responseType": "code"
    }
};

export default awsmobile;
